const currentUrl = window.location.href

const translation = {
  action: 'Action',
  add: 'Add',
  'add-retranslators': 'Add repeater',
  'add-terminal': 'Adding terminals',
  addCompany: 'Add a company',
  'addCompanyTransalter.addUser': 'ADD USER',
  'addCompanyTransalter.cancel': 'CANCEL',
  'addCompanyTransalter.description': 'Adding multiple terminals when moving to a new line.',
  'addCompanyTransalter.error.enterEmail': 'Please enter a valid email address',
  'addCompanyTransalter.error.enterName': 'Enter your username',
  'addCompanyTransalter.error.enterPassword': 'enter password',
  'addCompanyTransalter.error.name': 'Fill in the "Company Name" field',
  'addCompanyTransalter.error.password': 'Password mismatch',
  'addCompanyTransalter.error.repeatPassword': 'Passwords must match',
  'addCompanyTransalter.error.time': 'Fill in the "Time zone" field',
  'addCompanyTransalter.password': 'Password:',
  'addCompanyTransalter.repeatPassword': 'Confirm the password:',
  'addCompanyTransalter.save': 'Save',
  'addCompanyTransalter.selectCompany': 'Switch to selected company',
  'addLocatorTranslate.selectDateFrom': 'Select start date',
  'addLocatorTranslate.selectDateTo': 'Select completion date',
  'addLocatorTranslate.selectObject': 'Select object',
  'add_parametrs.accepted': 'Accepted',
  'add_parametrs.add_parametrs': 'Adding parameters',
  'add_parametrs.good': 'Fine',
  'add_parametrs.no_accepted': 'Not accepted',
  'add_parametrs.parametrs_available': 'Available parameters',
  'add_parametrs.parametrs_time': 'The following parameters have been successfully added:',
  'add_parametrs.protocol': 'Protocol',
  'add_parametrs.send_parametrs': 'Send parameters for addition',
  'add_parametrs.support': 'Contact support to add the following options (as they cannot be added)',
  'admin-panel': 'Admin. ',
  'admin-panel.company': 'Company',
  'admin-panel.download-finance': 'Download the report',
  'admin-panel.finance': 'Financial report',
  'admin-panel.is-company': 'Enter company!',
  'admin-panel.module': 'Module',
  'admin-panel.period': 'Period',
  'admin-panel.show': 'Show',
  'admin-panel.timezone': 'Timezone',
  'admin-panel.title': 'Admin panel',
  'adminPanelTranlsater.acceptDeleteTemplate': 'Are you sure you want to delete the Template?',
  'adminPanelTranlsater.addCompany': 'Add a company',
  'adminPanelTranlsater.cancel': 'Cancel',
  'adminPanelTranlsater.cashType.card': 'from map',
  'adminPanelTranlsater.cashType.deposit': 'manual',
  'adminPanelTranlsater.cashType.trial': 'trial version',
  'adminPanelTranlsater.cashType.withdrawal': 'tariff payment',
  'adminPanelTranlsater.command': 'Team',
  'adminPanelTranlsater.company': 'Company',
  'adminPanelTranlsater.copyObject': 'Copy object',
  'adminPanelTranlsater.copyTemplate': 'Copy Template',
  'adminPanelTranlsater.date': 'date',
  'adminPanelTranlsater.delete': 'Delete',
  'adminPanelTranlsater.enterEmail': 'enter email',
  'adminPanelTranlsater.grade': 'Grade',
  'adminPanelTranlsater.importKML': 'Import from KML',
  'adminPanelTranlsater.importWialon': 'Import from Wialon',
  'adminPanelTranlsater.invite': 'Invite',
  'adminPanelTranlsater.message': 'Message',
  'adminPanelTranlsater.name': 'Name',
  'adminPanelTranlsater.notData': 'No data available',
  'adminPanelTranlsater.numberType': 'No. Type',
  'adminPanelTranlsater.object': 'An object',
  'adminPanelTranlsater.period': 'Period',
  'adminPanelTranlsater.phone': 'Telephone',
  'adminPanelTranlsater.protocol': 'Protocol',
  'adminPanelTranlsater.recoveryObject': 'Restore object',
  'adminPanelTranlsater.total': 'Sum',
  'adminPanelTranlsater.type': 'Type',
  'adminPanelTranlsater.typeTerminal': 'Terminal type',
  'adminPanelTranlsater.user': 'User',
  'adminPanelTranlsater.wrongEmail': 'Invalid email',
  'admintariff.accept': 'Confirm',
  'admintariff.cancel': 'Cancel',
  'admintariff.changetitle': 'Tariff change',
  'admintariff.company': 'Company',
  'admintariff.date': 'Next payment date',
  'admintariff.days': 'days',
  'admintariff.for': 'behind',
  'admintariff.historyobjects': 'objects',
  'admintariff.newtariff': 'New tariff',
  'admintariff.object': 'object',
  'admintariff.objects': 'objects every',
  'admintariff.objectsnumber': 'Number of objects',
  'admintariff.oldtariff': 'Old tariff',
  'admintariff.paymentdate': 'payment date',
  'admintariff.period': 'Payment period',
  'admintariff.price': 'Cost of one object',
  'admintariff.refill': 'Top up',
  'admintariff.tariff': 'Rate',
  already_deleted_elements: 'items have been removed',
  'annotation.additional': 'Additionally:',
  'annotation.data_date': 'Data received date:',
  'annotation.gps_data': 'GPS Data:',
  'annotation.last_params': 'Latest data:',
  'annotation.reports': 'Reports:',
  'annotation.sensors': 'Sensors:',
  'annotationCameraTranslater.grid': 'Net',
  'annotationCameraTranslater.object': 'Object:',
  'annotationCameraTranslater.ribbon': 'Ribbon',
  'annotationCameraTranslater.see': 'Look',
  'annotationCameraTranslater.video': 'Video',
  'annotationCameraTranslater.videoRegister': 'Video recorder:',
  attention: 'Attention',
  author: 'Author',
  back: 'back',
  blocking: 'Blocking',
  'company.blocked': 'The company is blocked',
  'company.balance': 'Balance:',
  'company.history_pay': 'Write-off history',
  'company.go.pay': 'Pay',
  'company.blocked.text1': 'Access to the company is blocked. ',
  'company.blocked.text2': 'to resume access.',
  'company.blocked.text3': 'Access to the company is blocked. ',
  'btn.cancel': 'Cancel',
  'btn.create': 'Create',
  'btn.delete': 'Delete',
  'btn.editlist': 'EDIT LIST',
  'btn.finisheditlist': 'FINISH EDITING',
  'btn.save': 'Save',
  'btn.select': 'Choose',
  'btn.update': 'Update',
  cancel: 'Cancel',
  'catalog.user': "User's Guide",
  change: 'Change',
  close: 'Close',
  code: 'Code',
  'come.back': 'Return',
  'command-terminal': 'Team',
  companies: 'Companies',
  'companiesListTranslater.cancel': 'Cancel',
  'companiesListTranslater.copiedObject': 'copy object.',
  'companiesListTranslater.copyObject': 'Copying an object',
  'companiesListTranslater.save': 'Save',
  'companiesListTranslater.selectCompany': 'Select the company you want to join',
  'company.accounting_type': 'Accounting type:',
  'company.add_card_cost_note': 'To add an autopayment card, we will need to charge you. ',
  'company.autopayment': 'Auto payment',
  'company.autopayment_on': 'Enable auto payment',
  'company.autopayment_unavailable_with_mir': 'Autopayment is temporarily unavailable with MIR cards',
  'company.by_number_objects': 'By number of objects',
  'company.by_number_objects_sent_data': 'By the number of objects sending data',
  'company.cancel': 'Cancel',
  'company.card_add_for_autopayments': 'Adding a card for auto payment',
  'company.change_card_autopayment': 'Changing a card for auto payment',
  'company.change_card_cost_note': 'To change your auto payment card, we will need to debit 1 ruble from you. ',
  'company.choose_payment_system': 'Select payment system',
  'company.continue': 'Continue',
  'company.cost_one_object': 'Cost of 1 object:',
  'company.date-format': 'Date format',
  'company.download_offer': 'Download offer',
  'company.exitTime': 'Exit after n minutes of inactivity',
  'company.integrator': 'Dealer',
  'company.last_date_widget': 'Time of the last date in the widget',
  'company.last_date_widget_note': 'Specify which time format will be used when selecting the last date in a date range (in the date selection widget).',
  'company.max_number_objects': 'Max. ',
  'company.minimum_payment_amount': 'The minimum payment amount is 1 rub.',
  'company.name': 'Name',
  'company.no': 'No',
  'company.passwordLabel': 'Enhanced Security Mode',
  'company.passwordLabelText1': 'To increase security, new users will be required to create strong passwords when activating this mode. ',
  'company.passwordLabelText2': "When changing a user's password, they must log in using the administrator password and then set their own password for the account. ",
  'company.payment_period': 'Payment period:',
  'company.previous_period_amount': 'Amount for the previous period',
  'company.retransmit': 'Rebroadcast to EFIS',
  'company.select_payment_system': 'Select payment system:',
  'company.start_current_period': 'Start of the current period:',
  'company.temporarily_unavailable': 'Temporarily unavailable',
  'company.time-format': 'Time format',
  'company.timeZone': 'Timezone',
  'company.tooltip': '0 or empty field - there will be no exit due to inaction',
  'company.use_card_for_autopayments': 'Use a card for auto payment',
  'company.visa_or_mastercard': 'Visa or Mastercard',
  company_system_mir: 'MIR system',
  confirm: 'Confirm',
  'content.object_last_state_move': 'Last state: object in motion',
  'content.object_last_state_stay': 'Last state: object is standing',
  'content.object_move': 'Object in motion',
  'content.object_stay': 'The object is worth',
  copy: 'IMEI copied!',
  'copyReportTemplateTranslater.cancel': 'Cancel',
  'copyReportTemplateTranslater.copy': 'copy template.',
  'copyReportTemplateTranslater.save': 'Save',
  'copyReportTemplateTranslater.selectCompany': 'Select the company you want to join',
  'copyReportTemplateTranslater.title': 'Copying a template',
  create: 'Create',
  'createPayment.amount': 'Amount of payment',
  'createPayment.company': 'Company',
  'createPayment.createPayment': 'Create a payment',
  'createPayment.error.createPayment': 'Payment created',
  'createPayment.error.enterAmount': 'Fill in the "Payment Amount" field',
  'createPayment.error.enterCompany': 'Fill in the "Company" field',
  'createPayment.error.error': 'Error',
  'createPayment.numberPayment': 'Payment no.',
  'createPayment.title': 'Create a payment',
  'createPayment.upBalance': 'This operation will increase the balance of the selected company',
  'dealerTranslater.INN': 'TIN',
  'dealerTranslater.address': 'Address',
  'dealerTranslater.back': 'Back',
  'dealerTranslater.confirmApp': 'application',
  'dealerTranslater.confirmContacts': 'Contacts:',
  'dealerTranslater.confirmDescAdmin': 'Description of the Admin panel',
  'dealerTranslater.confirmDescBill': 'Tariff description',
  'dealerTranslater.confirmDescUi': 'Interface Description',
  'dealerTranslater.confirmDownload': 'Download',
  'dealerTranslater.confirmFastStart': 'Fast start',
  'dealerTranslater.confirmGoToApp': 'Go to application',
  'dealerTranslater.confirmHeader': 'Registration of a new integrator is completed',
  'dealerTranslater.confirmMaterials': 'Additional materials:',
  'dealerTranslater.confirmMsg': 'In the near future you will receive an email with a link to the support telegram chat; in the chat they will answer all your questions and help you with migration from another platform.',
  'dealerTranslater.email': 'Email',
  'dealerTranslater.enter': 'Specify',
  'dealerTranslater.enterCodeFromImage': 'To complete registration, enter the code from the image:',
  'dealerTranslater.enterEmail': 'Enter your email',
  'dealerTranslater.enterINN': 'Enter your TIN',
  'dealerTranslater.enterNameCompany': 'Enter company name',
  'dealerTranslater.enterPassword': 'enter password',
  'dealerTranslater.enterPhone': 'Enter your phone number',
  'dealerTranslater.enterSurname': 'Enter your full name',
  'dealerTranslater.goRegister': 'Register',
  'dealerTranslater.nameCompany': 'Company name',
  'dealerTranslater.next': 'Further',
  'dealerTranslater.password': 'Password',
  'dealerTranslater.register': 'New dealer registration',
  'dealerTranslater.registerNewDealer': 'New dealer registration',
  'dealerTranslater.rewritePassword': 'Confirm the password*',
  'dealerTranslater.services': 'Description of services',
  'dealerTranslater.surname': 'Full name',
  'dealerTranslater.tariff_description': 'Tariff description',
  'dealerTranslater.typeOrganization': 'Type of organization',
  delete: 'Delete',
  'demoTourTranslater.interface': 'Video review of the interface',
  'demoTourTranslater.link': 'Contact Developers',
  'demoTourTranslater.modules': 'Modules',
  'demoTourTranslater.next': 'Further',
  'demoTourTranslater.search': 'Search, notifications and settings',
  'demoTourTranslater.text': 'We strive to provide users with a clear and complete view of objects, so our application places great emphasis on interaction with the map, as it is the main visualization tool.',
  'demoTourTranslater.title': 'Your main tool is a map',
  'demoTourTranslater.video': 'Video review of the interface',
  'demoTourTranslater.watchVideoYouTube': 'Watch introductory video on YouTube',
  'demoTourTranslater.workMap': 'Working with the map',
  description: 'Description',
  details: 'Additionally',
  'edit-retranslators': 'Edit repeater',
  'edit-terminal': 'Editing a terminal',
  'editTarifTranslater.amount': 'Sum:',
  'editTarifTranslater.amountPer1Object': 'Cost for 1 object',
  'editTarifTranslater.changePeriod': 'You changed your payment period, so the next payment date has changed from',
  'editTarifTranslater.dateNextPayment': 'The next payment date cannot be in the past. ',
  'editTarifTranslater.dateStart': 'Tariff start date:',
  'editTarifTranslater.info': 'The payment amount for the payment period is formed according to the formula: Max. ',
  'editTarifTranslater.info_2': 'The payment amount for the payment period is formed according to the formula: Number of objects that sent data for the period, multiplied by the cost of 1 object',
  'editTarifTranslater.max': 'Max. ',
  'editTarifTranslater.nextPayment': 'The following tariff payment:',
  'editTarifTranslater.periodStart': 'Start of period',
  'editTarifTranslater.setBeforeDate': 'You set the start of the tariff to a past date, because of this the date of the next payment has changed from',
  'editTarifTranslater.setStartTarif': 'You have set the tariff to start on a future date, because of this the company will be in a trial period from',
  'editTarifTranslater.type': 'Accounting type',
  email: 'Mail',
  email_confirmation: 'Email confirmation',
  error: 'Error',
  'error.429': 'Too many attempts, try again later.',
  'error.502': 'Unfortunately, the server is temporarily unavailable. ',
  events: 'Event',
  'events.consumed': 'Fuel consumption, l',
  'events.create': 'Create an event',
  'events.datetime': 'Date Time',
  'events.description': 'Description',
  'events.deviation_minutes': 'Deviation, min',
  'events.duration_hours': 'Duration h',
  'events.duration_minutes': 'Duration, min',
  'events.filled': 'Fuel filled, l',
  'events.finish_date': 'Ending',
  'events.guaranteed': 'Warranty',
  'events.millage': 'Mileage km.',
  'events.motohours': 'Engine hours, h',
  'events.price': 'Price',
  'events.requiredFields': 'Required fields',
  'events.service_name': 'Service',
  'events.start_date': 'Start',
  'events.stolen': 'Fuel drained, l',
  'events.type_duration_hours': 'Duration, h',
  'events.type_name': 'Event type',
  'events.update': 'Edit event',
  'events.user_id': 'Driver',
  'events.validation.datetime': 'Enter date and time',
  'events.validation.number': 'Must be a number greater than 0',
  'events.validation.required': 'Obligatory field',
  'events.show': 'View event',
  'events.imageview.use.keyboard': 'Use the keys',
  'events.imageview.use.keyboard1': 'to switch between images',
  'events.imageview.images': 'Images:',
  'events.imageview.maxsize.msg': 'You cannot add images larger than 10MB.',
  'events.validation.driver': 'Select driver',
  excel: 'Excel',
  'fast_track.close': 'Hide quick track',
  'feedback.description': 'Share with us your experience of using our product',
  'feedback.fill_area': 'Fill in the field',
  'feedback.message_send': 'Your request has been sent. ',
  'feedback.question_offer': 'QuestionSuggestion',
  'feedback.send': 'Send',
  'feedback.yours': 'your feedback',
  'feedbackTranslater.addSomeTerminals': 'Adding multiple terminals when moving to a new line.',
  'feedbackTranslater.error.name': 'Fill in the "Company Name" field',
  'feedbackTranslater.error.time': 'Fill in the "Time zone" field',
  'feedbackTranslater.save': 'Save',
  'feedbackTranslater.selectCompany': 'Switch to selected company',
  fill_field: 'Search by objects',
  'formula.add': 'Add',
  'formula.errors.formula': 'Enter the formula',
  'formula.errors.name': 'Enter the title',
  'formula.extra_fields_sensors': 'Add extra ',
  'formula.tooltip': 'In the formula you can use the arithmetically operations +,-,/,*, brackets )(, any numbers',
  'geozones.add': 'Add',
  'geozones.blue': 'Blue',
  'geozones.color': 'Color',
  'geozones.create': 'Adding geofences',
  'geozones.delete': 'Delete',
  'geozones.edit': 'Editing a geofence',
  'geozones.editor': 'Editing',
  'geozones.errors.draw_circle': 'Draw a circle!',
  'geozones.errors.min_dots_line': 'You need at least 2 points for the line!',
  'geozones.errors.min_dots_polygon': 'You need at least 3 points for the polygon!',
  'geozones.errors.name': 'Enter your name!',
  'geozones.errors.put_marker': 'Place the marker on the map!',
  'geozones.errors.width': 'Please enter a valid width!',
  'geozones.figure': 'Figure',
  'geozones.green': 'Green',
  'geozones.grey': 'Grey',
  'geozones.hectar': 'ha',
  'geozones.km': 'km',
  'geozones.library': 'Geofence Directory',
  'geozones.meter': 'm',
  'geozones.name': 'Name',
  'geozones.orange': 'Orange',
  'geozones.red': 'Red',
  'geozones.square': 'Square:',
  'geozones.type': 'Type',
  'geozones.unitArea': 'Area units:',
  'geozones.use_as_address': 'Use as address',
  'geozones.never_cluster': 'Never cluster',
  'geozones.width': 'Thickness',
  'geozones_lang.geozones_enter': 'Login to geofences',
  'geozones_lang.geozones_exit': 'Leaving geofences',
  'guide.back': 'Back',
  'guide.company_workplace': 'Company workplace',
  'guide.instructions': 'Instructions',
  'guide.personal_account': "Dealer's personal account",
  'guide.quiestion_offer': 'QuestionSuggestion',
  'guide.short_instruction': 'Brief instructions',
  'guide.title': 'Reference',
  help: 'Reference',
  'helper.admin.text': 'To access the admin panel, you need to register as an integrator or request a demonstration of the platform by our specialist.',
  'helper.content_text': 'To work with sensors:',
  'helper.content_text_list.five': 'In the sensor you can select parameters and configure their calibrations; for this, click on',
  'helper.content_text_list.four': 'You will see a list of sensors in the object. ',
  'helper.content_text_list.one': '1. Open the “Objects” module',
  'helper.content_text_list.seven': 'To do this, in the “Formula” field, select the last item “Expression”.',
  'helper.content_text_list.six': 'In addition to standard formulas, you can create your own expressions for parameters with the ability to access raw sensor data.',
  'helper.content_text_list.three': '3. Select “Sensors” in the top left corner.',
  'helper.content_text_list.two': '2. Select an object by clicking on its name from the list',
  'helper.description_text': 'In demo mode, you do not have access to the admin panel and the ability to create/edit entities (objects, geofences, etc.).',
  'helper.footer.account': 'Register an account',
  'helper.footer.get_free': '(Get a 30 day free trial)',
  'helper.list.five.title': 'Admin Panel',
  'helper.list.four.title': 'Monitoring',
  'helper.list.one.content': 'Create and edit objects. ',
  'helper.list.one.link': 'Video: "Creating an Object"',
  'helper.list.one.title': 'Objects',
  'helper.list.three.title': 'Reports',
  'helper.list.two.content': 'To work with sensors: 1. Open the "Objects" module. ',
  'helper.list.two.title': 'Sensors',
  'helper.monitoring.colors.one': '— The data arrived less than 15 minutes ago',
  'helper.monitoring.colors.three': '— Data arrived more than 30 minutes ago',
  'helper.monitoring.colors.two': '— The data arrived less than 30 minutes ago',
  'helper.monitoring.statuses.four': '— The object is in motion',
  'helper.monitoring.statuses.one': '— The object is standing',
  'helper.monitoring.statuses.text': 'An object can have one of 4 statuses:',
  'helper.monitoring.statuses.three': '— The object is in motion',
  'helper.monitoring.statuses.two': '— The object is standing with the ignition on',
  'helper.monitoring.text_three': 'Also in monitoring you have access to quick actions related to an object. ',
  'helper.monitoring.text_two': 'So that you can quickly understand how long ago the data on the object arrived, we added a color indication:',
  'helper.objects': 'Create and edit objects. ',
  'helper.reports.text': 'Generate a report on an object or group of objects. ',
  'helper.reports.video_base_reports': 'Video: “Basic reports”',
  'helper.reports.video_create_template': 'Video: “Creating Templates”',
  'helper.video': 'Video: “Creating an object”',
  'helper.video_add_sensor': 'Video: "Adding sensors"',
  'helperTranslater.helper': 'Assistant',
  hide: 'ERASE',
  'hide.all': 'All elements',
  'hide.all.text': 'Hide all elements',
  'hide.events': 'Events',
  'hide.events.text': 'Hide events on the map',
  'hide.geozones': 'Settings for working with geofences',
  'hide.geozones.text': 'Hide geofences on the map',
  'hide.notifications': 'Notification settings',
  'hide.notifications.text': 'Hide notifications on map',
  'hide.objects': 'Objects',
  'hide.objects.text': 'Hide objects on the map',
  'hide.title': 'Visibility on the map',
  'hide.tracks': 'Tracks',
  'hide.tracks.direction_of_movement': 'Direction of movement',
  'hide.tracks.group': 'Group if distance is less',
  'hide.tracks.signatures': 'Hide captions on tracks',
  'hide.tracks.text': 'Hide tracks on the map',
  'hide.trips': 'Routes and Trips',
  'hide.trips.text': 'Hide routes and trips',
  'history.author': 'Author',
  'history.create': 'Creation',
  'history.date': 'Date Time',
  'history.delete': 'Removal',
  'history.edit': 'Editing',
  'history.event': 'Event',
  'history.operation': 'Operation',
  'history.title': 'History of changes',
  'importKmlTranslater.error.fileSize': 'Error: The file must be less than 5 MB.',
  'importKmlTranslater.error.formatKML': 'The file format should be kml',
  'importKmlTranslater.import': 'Import',
  'importKmlTranslater.inCompany': 'To the company',
  'importKmlTranslater.selectFile': 'Select a file',
  'importKmlTranslater.selectFileKML': 'Select geofenced KML file',
  'importKmlTranslater.title': 'Import from KML',
  'importWialonTranslater.back': 'Back',
  'importWialonTranslater.fileObject': 'Select a file with objects from Wialon',
  'importWialonTranslater.findEquipment': 'The following devices are detected, select the protocols for them:',
  'importWialonTranslater.findedParametrs': 'The following parameters have been detected selected in the sensors, check their compliance. ',
  'importWialonTranslater.import': 'Import',
  'importWialonTranslater.inCompany': 'To the company',
  'importWialonTranslater.next': 'Further',
  'importWialonTranslater.selectFile': 'Select a file',
  'importWialonTranslater.selectProtocol': 'Select protocol',
  'importWialonTranslater.title': 'Import from Wialon',
  'importWialonTranslater.typeFiles': '.wlp, .xml or zip archive of such files',
  in: 'IN',
  'integratorsTranslater.INN': 'TIN',
  'integratorsTranslater.addParameter': 'Adding an integrator',
  'integratorsTranslater.address': 'Address',
  'integratorsTranslater.cancel': 'Cancel',
  'integratorsTranslater.change': 'Change',
  'integratorsTranslater.contactPerson': 'The contact person',
  'integratorsTranslater.delete': 'Delete',
  'integratorsTranslater.documentMobileApp': 'The mobile application agreement will be displayed to your customers',
  'integratorsTranslater.editParameter': 'Editing an Integrator',
  'integratorsTranslater.email': 'Email',
  'integratorsTranslater.enterName': 'Enter the title',
  'integratorsTranslater.fieldMiss': 'Fill in the field',
  'integratorsTranslater.fileSize': 'The file size should not be more than 10MB',
  'integratorsTranslater.idShop': 'Store ID',
  'integratorsTranslater.moveOferta': 'Place your offer here or click to select',
  'integratorsTranslater.name': 'Name',
  'integratorsTranslater.numberCallCenter': 'Contact center number',
  'integratorsTranslater.ofertaLoaded': 'Offer loaded',
  'integratorsTranslater.password': 'Password',
  'integratorsTranslater.paySystem': 'Payment system',
  'integratorsTranslater.paymentInforamion': 'Payment information',
  'integratorsTranslater.periodPayment': 'Payment period (days)',
  'integratorsTranslater.priceObject': 'Cost per object',
  'integratorsTranslater.secretKey': 'The secret key',
  'integratorsTranslater.services': 'Services',
  'integratorsTranslater.smsTariff': 'SMS tariff',
  'integratorsTranslater.tariffDefault': 'Default tariff for new companies',
  'integratorsTranslater.tariffDefaultQR': 'Default rate for QR registrations',
  'integratorsTranslater.tariffDescription': 'Tariff description',
  'integratorsTranslater.tryPeriod': 'Trial period (days)',
  'integratorsTranslater.typeAccount': 'Accounting type',
  'integratorsTranslater.typeOrganization': 'Type of organization',
  'inviteUserTranslater.back': 'Back',
  'inviteUserTranslater.enterNumber': 'Enter your phone number in the format +XXXXXXXXXX or XXXXXXXXXX',
  'inviteUserTranslater.invite': 'Invite',
  'inviteUserTranslater.next': 'Further',
  'inviteUserTranslater.passwordMustBe': 'Password must be more than 5 characters',
  'inviteUserTranslater.register': 'Register',
  'inviteUserTranslater.wrongNumber': 'Incorrect phone number',
  'journal-events': 'The event log',
  'journal-events.filter': 'Filter',
  km_hour: 'km/h',
  'lkDiler.admin_panel.step_1': 'To create a company in the Admin Panel, you need to click on “Add company” in the “Companies” module:',
  'lkDiler.admin_panel.step_2': 'In the company creation form that opens, you need to fill in the company name, select the time zone and, if necessary, add 1 or more terminals and click “Save”. ',
  'lkDiler.admin_panel.step_3': 'Leave the option “Switch to the selected company” selected. You will automatically be taken to the created company.',
  'lkDiler.change_step.step_1': 'By default, in SKIF.PRO, newly created companies use a tariff of 250 rubles per object and the client has the opportunity to pay for the service through the processing center of the SKIF EPP company. ',
  'lkDiler.change_step.step_2': 'Select the required company from the list and click on the “Show” button',
  'lkDiler.change_step.step_3': 'Click the line with the tariff, select the number of objects 0 and the cost of one object 0, click confirm',
  'lkDiler.change_tariff': 'Tariff changes',
  'lkDiler.create_company': 'Creation of companies',
  'lkDiler.create_company_step.create': 'Creating a company in the Admin panel',
  'lkDiler.create_company_step.register': 'Registration via invitation link',
  'lkDiler.create_company_step.title': 'Creation of companies',
  'lkDiler.create_terminal': 'Establishment of terminals',
  'lkDiler.register_link.step_1': 'To send an invitation link, you must click “Invite” in the list of your companies.',
  'lkDiler.register_link.step_2': 'In the field that opens, you must enter the email address of the Company employee who will receive the invitation; by clicking on the link in the letter, he will be taken to the registration page for the new Company. ',
  'lkDiler.start_terminal.create_terminal': 'Establishment of terminals',
  'lkDiler.start_terminal.step_1': 'To add terminals, you need to go to the terminals module in your Admin Panel and click on "+"',
  'lkDiler.start_terminal.step_2': 'Next you need:',
  'lkDiler.start_terminal.step_3': '1. Select a company from the list of your companies in which you want to allow the use of terminals.',
  'lkDiler.start_terminal.step_4': '2. Enter one or more imei terminals that you want to add. ',
  'lkDiler.start_terminal.step_5': '3. If the terminals are connected using a password, you can specify it here.',
  'lkDiler.start_terminal.step_6': '4. Click on the save button.',
  'lkDiler.start_terminal.template.step_1': 'In the Dealer’s personal account, you can create command templates for terminals; to do this, go to the Admin Panel and select the “Command Reference” module',
  'lkDiler.start_terminal.template.step_2': 'Click on “+” to add a command, in the window that opens, select the terminal type, select or fill in the name of the command and enter the command itself in the “Command text” field, click save',
  'lkDiler.start_terminal.template.title': 'Command templates for terminals',
  'lkDiler.template_command': 'Command templates',
  'lkDiler.title': "Dealer's personal account",
  loading: 'Loading...',
  'loginPage.accept': 'I accept the terms and conditions stated in',
  'loginPage.addObject': 'Add object',
  'loginPage.autoName': 'Car name:',
  'loginPage.autoType': 'Car type:',
  'loginPage.back': 'Back',
  'loginPage.btn.send': 'Send',
  'loginPage.carName': 'car name',
  'loginPage.clickOnStartTheBot-': 'Click on Start, the bot returns the mailing ID for Telegram',
  'loginPage.company.name': 'Name of the company',
  'loginPage.company.submit': 'To come in',
  'loginPage.company.title': 'Choosing a company',
  'loginPage.confirm.submit': 'To main',
  'loginPage.confirm.text1': 'The company is registered.',
  'loginPage.confirm.text2': 'To confirm registration, follow the link in the letter sent to the specified email',
  'loginPage.confirm.title': 'Confirmation',
  'loginPage.dontHaveAccount': 'I am a new user of SKIF.PRO2',
  'loginPage.email': 'Email',
  'loginPage.enterCode': 'Enter the six-digit code:',
  'loginPage.enterTheTelegramBot': 'Login to telegram bot',
  'loginPage.errors.email': 'Enter email',
  'loginPage.errors.emailError': 'Enter an existing email',
  'loginPage.errors.name': 'Enter your name',
  'loginPage.errors.nameCompany': 'Enter your company name',
  'loginPage.errors.pass': 'enter password',
  'loginPage.errors.region': 'Choose region',
  'loginPage.errors.telegramChatId': 'Enter mailing id for Telegram',
  'loginPage.haveAccount': 'I have an account',
  'loginPage.hint.companyName': 'Select a company',
  'loginPage.hint.lang': 'Choose language',
  'loginPage.hint.timeZone': 'Select time zone',
  'loginPage.lang': 'Language',
  'loginPage.linkTermsOfUse': '"User Agreement"',
  'loginPage.links.ifHaveAccount': 'Do you have an account?',
  'loginPage.links.ifHaveNotAccount': "Don't have an account?",
  'loginPage.links.login': 'To come in',
  'loginPage.links.lostPass': 'Forgot your password?',
  'loginPage.links.register': 'Registration',
  'loginPage.login': 'Login',
  'loginPage.login.guest': 'Demo login',
  'loginPage.login.submit': 'To come in',
  'loginPage.login.title': 'Entrance',
  'loginPage.mail': 'Your email',
  'loginPage.name': 'your name',
  'loginPage.name.label': 'Name:',
  'loginPage.nameCompany': 'Name of the company',
  'loginPage.offer': 'offer',
  'loginPage.orgName': 'Name of your organization:',
  'loginPage.orgName.holder': 'Name of the organization',
  'loginPage.pass': 'Password',
  'loginPage.passHint': 'Your password consists of at least 4 characters.',
  'loginPage.password': 'Password:',
  'loginPage.phoneHolder': 'phone number in the format +XXXXXXXXXXXX',
  'loginPage.recovery.text1': 'To restore your account, go to',
  'loginPage.recovery.text2': 'via the link in the letter sent to the specified email.',
  'loginPage.recovery.title': 'Recovery',
  'loginPage.recovery.toMain': 'To main',
  'loginPage.register': 'Register',
  'loginPage.register.submit': 'Register',
  'loginPage.register.title': 'Registration',
  'loginPage.sixCode': 'six-digit code:',
  'loginPage.telegramChatId': 'Mailing IDs for Telegram',
  'loginPage.textTermsOfUse': 'By clicking "Register" you accept the terms',
  'loginPage.timeZone': 'Timezone',
  'loginPage.timeZones': 'Timezone:',
  'loginPage.userName': 'Username',
  'loginPage.validation.acceptTerms': 'please accept the terms',
  'loginPage.validation.choosecartype': 'Select machine type',
  'loginPage.validation.codeType': 'The six-digit code must consist of capital English letters and numbers',
  'loginPage.validation.email': 'Enter your email',
  'loginPage.validation.email.correctform': 'please enter correct email address',
  'loginPage.validation.enter6code': 'You must enter a six-digit code',
  'loginPage.validation.entercarname': 'Enter machine name',
  'loginPage.validation.name': 'Enter your name',
  'loginPage.validation.password': 'Enter your password',
  'loginPage.validation.sixCode': 'You must enter a six-digit code:',
  'loginPage.validation.telephone': 'Enter your phone number',
  'loginPage.validation.tiemzone': 'Select time zone',
  'loginPage.yourpassword': 'your password',
  'loginPass.changePass.adminPass': 'Enter the password received from the administrator',
  'loginPass.changePass.confirmNewPass': 'Confirm the password',
  'loginPass.changePass.current': 'Current Password',
  'loginPass.changePass.empty': 'If you leave the field blank, you will receive a confirmation of your password change by email.',
  'loginPass.changePass.inputNewPass': 'Enter a new password',
  'loginPass.changePass.inputPass': 'enter password',
  'loginPass.changePass.newPass': 'New Password',
  'loginPass.changePass.newPass_confirm': 'New password again',
  'loginPass.changePass.old_pass': 'Old Password',
  'loginPass.changePass.repeatNewPass': 'Repeat password',
  'loginPass.changePass.submit': 'To come in',
  'loginPass.changePass.title': 'Change Password',
  'loginPass.errors.lengthPass': 'The password must be between 5 and 25 characters',
  'loginPass.errors.lengthPass.strongPass.adminUser': 'The password must be between 15 and 25 characters and consist of at least one uppercase English letter, at least one lowercase English letter, and at least one special character (~ ! @ # $ % ^ & * ( ) - = + [ "{ ] "  < . > / ?)',
  'loginPass.errors.lengthPass.strongPass.regularUser': 'The password must be between 8 and 25 characters and consist of at least one uppercase English letter, at least one lowercase English letter, and at least one special character (~ ! @ # $ % ^ & * ( ) - = + [ "{ ] "  < . > / ?)',
  'loginPass.errors.newPass': 'Enter your password again',
  'loginPass.errors.notMatchPass': 'The entered passwords do not match',
  'loginPass.errors.pass': 'enter password',
  'loginPass.recovery.back': 'Back',
  'loginPass.recovery.content': 'To restore your account, follow the link in the letter sent by email. ',
  'loginPass.recovery.hint': 'A letter with a link to reset your password will be sent to the specified e-mail address.',
  'loginPass.recovery.title': 'Password recovery',
  logout: 'Go out',
  'mailing.adresses': 'Addressees',
  'mailing.adresses.warning': 'Enter your mailing addressees!',
  'mailing.chooseGroup': 'Choose a group!',
  'mailing.chooseObject': 'Select an object!',
  'mailing.create': 'Create a newsletter',
  'mailing.date': 'date',
  'mailing.daysweek': 'Days of the week',
  'mailing.daysweek.warning': 'Enter the days of the week!',
  'mailing.edit': 'Change',
  'mailing.enterName': 'Enter your name!',
  'mailing.isActive': 'Activity',
  'mailing.mailing': 'Newsletter',
  'mailing.tooltip': 'The date and time of sending the first message will be calculated depending on the selected period and the date of the last sending',
  'mailing.name': 'Name',
  'mailing.object': 'An object',
  'mailing.objectGroup': 'Group of objects',
  'mailing.periodicity': 'Periodicity',
  'mailing.periodicity.warning': 'Enter frequency!',
  'mailing.subject': 'Message subject',
  'mailing.subject.warning': 'Enter your message!',
  'mailing.template': 'Select a template',
  'mailing.timeDateSent': 'Last sent date/time',
  'mailing.title': 'Mailing list directory',
  'mailing.vehicle': 'Selecting a vehicle',
  'mainPage.back': 'To home!',
  'mainPage.sorry': 'Unfortunately your link no longer works. ',
  'mainPage.title': 'Welcome to SKIF!',
  'map.adrress_not_found': 'Address not found',
  'map.bing': 'Bing',
  'map.bingSatellite': 'Bing Satellite',
  'map.googleHybrid': 'Google Hybrid',
  'map.googleRoadMap': 'Google Roadmap',
  'map.googleSatelite': 'Google Satellite',
  'map.googleTerrain': 'Google Terrain',
  'map.here': 'Here',
  'map.osm': 'O.S.M.',
  'map.search': 'Finding an exact address',
  'map.style': 'Card style',
  'map.title': 'Map',
  'map.yandex': 'Yandex maps',
  'measure.cancel': 'Cancel',
  'measure.clear': 'CLEAR',
  'measure.description': 'You can change the dimension by moving the point',
  'measure.distance': 'Distance',
  'measure.finish': 'Finish measurements',
  'measure.lastPoint': 'Last point',
  'measure.line': 'Line',
  'measure.perimeter': 'Perimeter',
  'measure.polygon': 'Polygon',
  'measure.region': 'Region',
  'measure.square': 'Square',
  'measure.title': 'Measuring distances and areas',
  'menuSelect.geozones': 'Geofences',
  'menuSelect.monitoring': 'Monitoring',
  'menuSelect.notifications': 'Notifications',
  'menuSelect.objects': 'Objects',
  'menuSelect.race': 'Trips',
  'menuSelect.reports': 'Reports',
  'menuSelect.routes': 'Routes',
  'menuSelect.users': 'Users',
  monitoring: 'Monitoring',
  'monitoring.accept': 'Apply',
  'monitoring.activeFilters': 'Active filters',
  'monitoring.add-objects': 'Add objects',
  'monitoring.addFilter': 'Add filter',
  'monitoring.apply_filter': 'Apply filters',
  'monitoring.change-object': 'Change object',
  'monitoring.choose': 'Choose',
  'monitoring.columns.name': 'Name',
  'monitoring.command_response': 'Answer',
  'monitoring.commands': 'Teams',
  'monitoring.commands.columns.command': 'Team',
  'monitoring.commands.columns.data': 'Date Time',
  'monitoring.commands.columns.status': 'Status',
  'monitoring.commands.error_message': 'Fill in the "Select object" and "Command" fields',
  'monitoring.commands.exit_number': 'Output number',
  'monitoring.commands.object': 'An object',
  'monitoring.create-event': 'Create an event',
  'monitoring.date-and-time': 'date and time',
  'monitoring.datepoint': 'Been in touch',
  'monitoring.display_all': 'Show all',
  'monitoring.displayed_objects': 'Displayed objects:',
  'monitoring.distance_group': 'Group if distance is less than (screen pixels):',
  'monitoring.editObject': 'Edit object',
  'monitoring.enter-description': 'Enter a description',
  'monitoring.fast-report': 'Quick report',
  'monitoring.fast-track': 'Fast track',
  'monitoring.fast_tracks': 'Fast tracks',
  'monitoring.fast_tracks.month': 'Per month',
  'monitoring.fast_tracks.today': 'For today',
  'monitoring.fast_tracks.week': 'During the week',
  'monitoring.fast_tracks.yesterday': 'For yesterday',
  'monitoring.fast_tracks.custom': 'Another period',
  'monitoring.filter-journal-events': 'Event log filter',
  'monitoring.filter.customFields': 'Add. ',
  'monitoring.filter.driver': 'Driver',
  'monitoring.filter.event': 'Events',
  'monitoring.filter.fieldsEmpty': 'All fields must be completed',
  'monitoring.filter.imei': 'IMEI',
  'monitoring.filter.name': 'Name',
  'monitoring.filter.phoneNumber': 'Phones',
  'monitoring.filter.sensorName': 'Sensor names',
  'monitoring.filter.sensorParam': 'Sensors parameter',
  'monitoring.filter.sensorType': 'Sensor types',
  'monitoring.filterMax': 'You cannot add more than 30 filters',
  'monitoring.filters': 'Filters',
  'monitoring.graph': 'Schedule',
  'monitoring.grouping_on_map': 'Grouping on the map',
  'monitoring.groups.edit': 'Editing',
  'monitoring.map_work_settings': 'Map settings',
  'monitoring.maxUnits': 'You cannot display more than 300 objects at once; use a filter to reduce the number of objects.',
  'monitoring.object_search': 'Search by object name',
  'monitoring.objects': 'Objects',
  'monitoring.objects_grouping': 'Grouping objects',
  'monitoring.objects_list': 'List of objects:',
  'monitoring.objects_on_map': 'Objects on the map:',
  'monitoring.position': 'Position',
  'monitoring.registration-event': 'Event registration',
  'monitoring.search.custom_fields': 'Add. ',
  'monitoring.search.driver': 'Driver',
  'monitoring.search.events': 'Events',
  'monitoring.search.groups': 'Groups',
  'monitoring.search.imei': 'IMEI',
  'monitoring.search.name': 'Name',
  'monitoring.search.phonenumber': 'Phones',
  'monitoring.search.sensors_name': 'Sensor names',
  'monitoring.search.sensors_param': 'Sensors parameter',
  'monitoring.search.sensors_types': 'Sensor types',
  'monitoring.searchHolder': 'ignition, fuel',
  'monitoring.send-command': 'Will send a command',
  'monitoring.sendCommand-title': 'Send command',
  'monitoring.send_command': 'Send command',
  'monitoring.shape_size': 'Shape size:',
  'monitoring.show_address': 'Show object address',
  'monitoring.show_annotation_on_click': 'Show annotation when clicking on an object in the monitoring list',
  'monitoring.terminal_on': 'Terminal connected',
  'monitoring.to_reports': 'To reports',
  'monitoring.track_all': 'Track everything',
  'monitoring.trips': 'Trips',
  'monitoring.type-event': 'Event type',
  'monitoring.view-events': 'Event type',
  'monitoring.violation': 'Violation',
  'monitoringListTranslater.withoutgroup': 'Without a group',
  name: 'Name',
  'name.column': 'Column title',
  'nav.admin': 'Admin panel',
  'nav.geozones': 'Geofences',
  'nav.mailing': 'Newsletter',
  'nav.monitoring': 'Monitoring',
  'nav.notifications': 'Notifications',
  'nav.objects': 'Objects',
  'nav.reports': 'Reports',
  'nav.trips': 'Trips',
  'nav.users': 'Users',
  'new_units.add.add_field': 'Add a field',
  'new_units.add.couplings': 'Add',
  'new_units.add.groups': 'Add a group',
  'new_units.add.links': 'Create link',
  'new_units.add.objects': 'Add object',
  'new_units.add.parameters': 'Add options',
  'new_units.add.service': 'Add maintenance',
  'new_units.add.shifts': 'Add',
  'new_units.add.trailers': 'Add trailer',
  'new_units.apply_changes': 'Apply changes',
  'new_units.approve': 'Confirm',
  'new_units.cancel': 'cancel changes',
  'new_units.create': 'Create',
  'new_units.delete': 'delete',
  'new_units.edit': 'Edit',
  'new_units.footer.cancel': 'Cancel',
  'new_units.footer.save': 'Save',
  'new_units.groups.errors.create': 'Oops... There was a problem creating',
  'new_units.groups.errors.delete': 'An error occurred while deleting groups!',
  'new_units.groups.errors.edit': 'Oops... There was a problem editing',
  'new_units.groups.errors.name': 'Enter your name!',
  'new_units.groups.errors.validation': 'Fill in required fields!',
  'new_units.groups.no_groups': 'No object groups have been created',
  'new_units.groups.search': 'Search by group',
  'new_units.groups.search_options.name': 'Name',
  'new_units.groups.search_options.object': 'An object',
  'new_units.groups.success.create': 'The group has been successfully created!',
  'new_units.groups.success.delete': 'Groups successfully deleted!',
  'new_units.groups.success.edit': 'The group has been successfully edited!',
  'new_units.groups.table_columns.group_name': 'Group name',
  'new_units.groups.table_columns.objects': 'Objects',
  'new_units.groups.table_columns.users_count': 'Users',
  'new_units.links.accessEnd': 'Access end date',
  'new_units.links.accessStart': 'Access start date',
  'new_units.links.create': 'Create access link',
  'new_units.links.description': 'Description',
  'new_units.links.edit': 'Edit link',
  'new_units.links.empty': 'Not selected',
  'new_units.links.errors.access_end': 'Select a completion date!',
  'new_units.links.errors.access_start': 'Select a start date!',
  'new_units.links.errors.create': 'An error occurred while creating the access link!',
  'new_units.links.errors.delete': 'An error occurred while deleting access links!',
  'new_units.links.errors.edit': 'An error occurred while editing the access link!',
  'new_units.links.errors.unit': 'Select an object!',
  'new_units.links.link': 'Link',
  'new_units.links.no_links': 'The list of access links is empty',
  'new_units.links.object': 'An object',
  'new_units.links.placeholder': 'Long description....',
  'new_units.links.search': 'Search by links',
  'new_units.links.search_options.object': 'An object',
  'new_units.links.search_options.status': 'Status',
  'new_units.links.status.active_off': 'Active (off)',
  'new_units.links.status.active_on': 'Active (on)',
  'new_units.links.status.inactive': 'Inactive',
  'new_units.links.status.planned': 'Planned',
  'new_units.links.success.create': 'Access link created successfully!',
  'new_units.links.success.create_imei': 'Imei code has been successfully created!',
  'new_units.links.success.delete': 'Access links successfully removed!',
  'new_units.links.success.edit': 'The access link has been successfully edited!',
  'new_units.links.success.edit_imei': 'Imei code has been successfully edited!',
  'new_units.links.table_columns.active_from': 'Active from',
  'new_units.links.table_columns.active_to': 'Active by',
  'new_units.links.table_columns.object': 'An object',
  'new_units.links.table_columns.status': 'Status',
  'new_units.not_selected': 'not selected',
  'new_units.objects.approved': 'CONFIRMED',
  'new_units.objects.create': 'Creating an Object',
  'new_units.objects.edit': 'Editing an object',
  'new_units.objects.errors.delete': 'An error occurred while deleting objects!',
  'new_units.objects.fields.basic.errors.custom_imei': 'Have it locked!',
  'new_units.objects.fields.basic.errors.imei': 'Choose a unique ID!',
  'new_units.objects.fields.basic.errors.name': 'Enter your name!',
  'new_units.objects.fields.basic.errors.phonenumber': 'Enter your phone!',
  'new_units.objects.fields.basic.errors.terminal_type': 'Select device type!',
  'new_units.objects.fields.basic.errors.type': 'Select object type!',
  'new_units.objects.fields.basic.gadget': 'Device (terminal):',
  'new_units.objects.fields.basic.groups': 'Groups',
  'new_units.objects.fields.basic.imei': 'IMEI:',
  'new_units.objects.fields.basic.name': 'Name of the property:',
  'new_units.objects.fields.basic.note': "You don't have to specify the device name. ",
  'new_units.objects.fields.basic.port': 'Port:',
  'new_units.objects.fields.basic.protocol': 'Data exchange protocol:',
  'new_units.objects.fields.basic.server': 'Data receiving server:',
  'new_units.objects.fields.basic.sim_1': 'Sim1:',
  'new_units.objects.fields.basic.sim_2': 'Sim2:',
  'new_units.objects.fields.basic.type': 'Object type:',
  'new_units.objects.fields.couplings.date_filter': 'Filter by date:',
  'new_units.objects.fields.couplings.end': 'By',
  'new_units.objects.fields.couplings.end_date': 'Date of completion:',
  'new_units.objects.fields.couplings.errors.already_deleted': 'You have already deleted this link, if you want to return it click on the cancel button below.',
  'new_units.objects.fields.couplings.errors.approved': 'Please confirm your current trailer to create a new hitch!',
  'new_units.objects.fields.couplings.errors.couplig_end': 'Select a completion date!',
  'new_units.objects.fields.couplings.errors.couplig_start': 'Select a start date!',
  'new_units.objects.fields.couplings.errors.trailer': 'Choose a trailer!',
  'new_units.objects.fields.couplings.errors.work_type': 'Choose the type of work!',
  'new_units.objects.fields.couplings.max_speed': 'Max. ',
  'new_units.objects.fields.couplings.min_speed': 'Min. ',
  'new_units.objects.fields.couplings.no_couplings': 'Work with trailers is not planned',
  'new_units.objects.fields.couplings.note': 'The trailer usage interval overlaps with another interval.',
  'new_units.objects.fields.couplings.sensor_mask': 'Sensor name mask:',
  'new_units.objects.fields.couplings.start': 'WITH',
  'new_units.objects.fields.couplings.start_date': 'Start date:',
  'new_units.objects.fields.couplings.success.approved': 'The coupling was successfully confirmed!',
  'new_units.objects.fields.couplings.success.updated': 'The hitch has been successfully updated',
  'new_units.objects.fields.couplings.table_columns.from': 'WITH',
  'new_units.objects.fields.couplings.table_columns.to': 'By',
  'new_units.objects.fields.couplings.table_columns.trailer': 'Trailer',
  'new_units.objects.fields.couplings.title': 'Planning:',
  'new_units.objects.fields.couplings.trailer': 'Trailer',
  'new_units.objects.fields.couplings.work_type': 'Type of work:',
  'new_units.objects.fields.parameters.errors.correct_values': 'Fill in the correct values ​​to go to the section!',
  'new_units.objects.fields.parameters.errors.wrong_value': 'Incorrect value!',
  'new_units.objects.fields.parameters.name': 'Name:',
  'new_units.objects.fields.parameters.no_parameters': "You haven't added any additional parameters",
  'new_units.objects.fields.parameters.switch.hint': 'Show in monitoring',
  'new_units.objects.fields.parameters.switch.not_show_annotation': 'Do not display in annotation',
  'new_units.objects.fields.parameters.switch.show_annotation': 'Show in annotation',
  'new_units.objects.fields.parameters.tabs.additional': 'Additionally',
  'new_units.objects.fields.parameters.tabs.engine_hours': 'Engine hours',
  'new_units.objects.fields.parameters.tabs.filter_data': 'Data filtering',
  'new_units.objects.fields.parameters.tabs.fuel': 'Fuel',
  'new_units.objects.fields.parameters.tabs.parameters': 'Your parameters',
  'new_units.objects.fields.parameters.tabs.trips': 'Trips',
  'new_units.objects.fields.parameters.tabs.video': 'Video',
  'new_units.objects.fields.parameters.value': 'Meaning:',
  'new_units.objects.fields.parameters.video.hint': 'Enter the channel numbers separated by commas to display the video.',
  'new_units.objects.fields.sensors.fields.connected_sensors.bundle_with_ignition': 'Link to ignition sensor',
  'new_units.objects.fields.sensors.fields.connected_sensors.cancel': 'Cancel',
  'new_units.objects.fields.sensors.fields.connected_sensors.connected': 'Bound',
  'new_units.objects.fields.sensors.fields.connected_sensors.title': 'Connection with sensors',
  'new_units.objects.fields.sensors.fields.errors.duplicate_lower_bound': 'Lower bound values ​​cannot be repeated!',
  'new_units.objects.fields.sensors.fields.errors.duplicate_x': 'X values ​​cannot be repeated!',
  'new_units.objects.fields.sensors.fields.errors.formula': 'Choose a formula!',
  'new_units.objects.fields.sensors.fields.errors.invalid_value': 'You cannot add a new row because there is an invalid value in the table.',
  'new_units.objects.fields.sensors.fields.errors.name': 'Enter the name of the sensor!',
  'new_units.objects.fields.sensors.fields.errors.parameter_1': 'Select option 1!',
  'new_units.objects.fields.sensors.fields.errors.parameter_2': 'Select option 2!',
  'new_units.objects.fields.sensors.fields.errors.save_validation': 'Correct the error in the current sensor in order to save!',
  'new_units.objects.fields.sensors.fields.errors.smoothing': 'Please enter the correct value!',
  'new_units.objects.fields.sensors.fields.errors.type': 'Select sensor type!',
  'new_units.objects.fields.sensors.fields.errors.validation': 'Correct the error in the current sensor in order to move on to another!',
  'new_units.objects.fields.sensors.fields.expression': 'Expression',
  'new_units.objects.fields.sensors.fields.formula': 'Formula',
  'new_units.objects.fields.sensors.fields.help_info': 'Using a mouse click, select the format for working with sensor parameters.',
  'new_units.objects.fields.sensors.fields.hidden_in_both': 'Hidden in reports and monitoring',
  'new_units.objects.fields.sensors.fields.hidden_in_monitoring': 'Hidden in monitoring',
  'new_units.objects.fields.sensors.fields.hidden_in_report': 'Hidden in reports',
  'new_units.objects.fields.sensors.fields.hide_in_monitoring': 'Hide in monitoring',
  'new_units.objects.fields.sensors.fields.hide_in_report': 'Hide in reports',
  'new_units.objects.fields.sensors.fields.hint.hide': 'Hide',
  'new_units.objects.fields.sensors.fields.hint.show': 'Show',
  'new_units.objects.fields.sensors.fields.hint.title': 'Clue',
  'new_units.objects.fields.sensors.fields.multiple_deletion_mode': 'You are in sensor removal mode',
  'new_units.objects.fields.sensors.fields.name': 'Name:',
  'new_units.objects.fields.sensors.fields.new_sensor': 'New sensor',
  'new_units.objects.fields.sensors.fields.parameters.a': 'A',
  'new_units.objects.fields.sensors.fields.parameters.b': 'B',
  'new_units.objects.fields.sensors.fields.parameters.clear': 'Clear table',
  'new_units.objects.fields.sensors.fields.parameters.download_as_csv': 'Download X,Y as CSV file',
  'new_units.objects.fields.sensors.fields.parameters.download_from_csv': 'Load X and Y from CSV file',
  'new_units.objects.fields.sensors.fields.parameters.exist': 'Eat',
  'new_units.objects.fields.sensors.fields.parameters.helpers.degree': 'degree',
  'new_units.objects.fields.sensors.fields.parameters.helpers.param': 'Parameter',
  'new_units.objects.fields.sensors.fields.parameters.helpers.param1': 'param1',
  'new_units.objects.fields.sensors.fields.parameters.helpers.param1_minus_1': 'param1[-1]',
  'new_units.objects.fields.sensors.fields.parameters.helpers.param2': 'param2',
  'new_units.objects.fields.sensors.fields.parameters.helpers.param2_minus_1': 'param2[-1]',
  'new_units.objects.fields.sensors.fields.parameters.helpers.param_minus_1': 'Parameter[-1]',
  'new_units.objects.fields.sensors.fields.parameters.helpers.sensor_name': 'Name of the hospital',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.byte_control': 'bitwise parameter control (for example param101:3 - the third bit of the param101 parameter)',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.param': 'accessing the values ​​of any parameter from raw data',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.param1': 'access to the first parameter (after linear transformation) of the current sensor',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.param1_minus_1': 'access to the previous value of the first parameter (after linear transformation) of the current sensor',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.param2': 'access to the second parameter (after linear transformation) of the current sensor',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.param2_minus_1': 'access to the previous value of the second parameter (after linear transformation) of the current sensor',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.param_minus_1': 'accessing the previous parameter value from raw data',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.sensor_name': 'accessing the value of other sensors',
  'new_units.objects.fields.sensors.fields.parameters.hide_graph': 'Hide chart',
  'new_units.objects.fields.sensors.fields.parameters.lower_bound_short': '(lower edge)',
  'new_units.objects.fields.sensors.fields.parameters.lower_bound_x': 'Lower Bound X:',
  'new_units.objects.fields.sensors.fields.parameters.param_option_1': 'Parameter 1:',
  'new_units.objects.fields.sensors.fields.parameters.param_option_2': 'Parameter 2:',
  'new_units.objects.fields.sensors.fields.parameters.param_title_1': 'Parameter 1',
  'new_units.objects.fields.sensors.fields.parameters.param_title_2': 'Parameter 2',
  'new_units.objects.fields.sensors.fields.parameters.param_title_s_1': 'S1',
  'new_units.objects.fields.sensors.fields.parameters.param_title_s_2': 'S2',
  'new_units.objects.fields.sensors.fields.parameters.related_tables': 'Related tables',
  'new_units.objects.fields.sensors.fields.parameters.sensor_calibration': 'Sensor calibration',
  'new_units.objects.fields.sensors.fields.parameters.show_graph': 'Show graph',
  'new_units.objects.fields.sensors.fields.parameters.smoothing_coefficient': 'Smoothing factor',
  'new_units.objects.fields.sensors.fields.parameters.smoothing_coefficient_range': '(0 to 999)',
  'new_units.objects.fields.sensors.fields.parameters.type_filter': 'Filtration type',
  'new_units.objects.fields.sensors.fields.parameters.upper_bound_x': 'Upper limit X:',
  'new_units.objects.fields.sensors.fields.parameters.x': 'X',
  'new_units.objects.fields.sensors.fields.parameters.y': 'Y',
  'new_units.objects.fields.sensors.fields.sensor_type': 'Sensor type:',
  'new_units.objects.fields.sensors.fields.technical_params.add_params': 'Add tech. ',
  'new_units.objects.fields.sensors.fields.technical_params.errors.wrong_value': 'Incorrect value!',
  'new_units.objects.fields.sensors.fields.technical_params.show_annotation': 'Displaying a parameter in an object annotation.',
  'new_units.objects.fields.sensors.fields.technical_params.title': 'Technical specifications',
  'new_units.objects.fields.sensors.no_sensors': 'No sensors have been created in the object',
  'new_units.objects.fields.sensors.output_text': 'Output text',
  'new_units.objects.fields.sensors.title': 'Sensors:',
  'new_units.objects.fields.sensors.value': 'Meaning',
  'new_units.objects.fields.sensors.value_to_sensor': 'Convert values ​​to text:',
  'new_units.objects.fields.sensors.value_to_sensor_text': "Convert the sensor's numeric value to text. ",
  'new_units.objects.fields.service.days': 'Days',
  'new_units.objects.fields.service.errors.already_deleted': 'You have already deleted this shift, if you want to return it, click on the cancel button below.',
  'new_units.objects.fields.service.errors.checkbox': 'Select 1 of the interval types!',
  'new_units.objects.fields.service.errors.description': 'Enter a description!',
  'new_units.objects.fields.service.errors.intervalDays': 'Enter interval (days)!',
  'new_units.objects.fields.service.errors.intervalKm': 'Enter your current mileage (km)!',
  'new_units.objects.fields.service.errors.intervalMotohours': 'Enter the interval (engine hours)!',
  'new_units.objects.fields.service.errors.lastDays': 'Select the date of the last maintenance!',
  'new_units.objects.fields.service.errors.lastKm': 'Enter your current mileage (km)!',
  'new_units.objects.fields.service.errors.lastMotohours': 'Enter last (time)!',
  'new_units.objects.fields.service.errors.name': 'Enter the title!',
  'new_units.objects.fields.service.errors.validation': 'Please fill out all required fields!',
  'new_units.objects.fields.service.fields.ativate_engine_hours': 'Activate engine hour interval',
  'new_units.objects.fields.service.fields.current_mileage': 'Tech. ',
  'new_units.objects.fields.service.fields.days_period': 'Activate interval by day',
  'new_units.objects.fields.service.fields.description': 'Description:',
  'new_units.objects.fields.service.fields.engine_hours': 'Interval (M/h):',
  'new_units.objects.fields.service.fields.interval': 'Interval (km):',
  'new_units.objects.fields.service.fields.interval_days': 'Interval in days:',
  'new_units.objects.fields.service.fields.last_serviced': 'Date of last maintenance:',
  'new_units.objects.fields.service.fields.last_times': 'Last time:',
  'new_units.objects.fields.service.fields.mileage_period': 'Activate mileage interval',
  'new_units.objects.fields.service.fields.mileage_period.alert': 'The maintenance report requires adding a mileage sensor',
  'new_units.objects.fields.service.fields.mileage_period.alertSecond': 'The maintenance report requires adding an absolute engine hour sensor',
  'new_units.objects.fields.service.fields.service': 'Service:',
  'new_units.objects.fields.service.mileage': 'Mileage/M.h.',
  'new_units.objects.fields.service.no_service': 'TO list is empty',
  'new_units.objects.fields.service.service': 'Service',
  'new_units.objects.fields.service.table_columns.days': 'Days',
  'new_units.objects.fields.service.table_columns.mileage': 'Mileage/M.h.',
  'new_units.objects.fields.service.table_columns.service': 'Service',
  'new_units.objects.fields.service.title': 'THAT:',
  'new_units.objects.fields.shifts.additionalFields': 'Additional fields:',
  'new_units.objects.fields.shifts.driver': 'Driver',
  'new_units.objects.fields.shifts.end': 'By',
  'new_units.objects.fields.shifts.errors.already_deleted': 'You have already deleted this shift, if you want to return it, click on the cancel button below.',
  'new_units.objects.fields.shifts.errors.approved': 'Please confirm your current shift to create a new one!',
  'new_units.objects.fields.shifts.errors.driver': 'Choose a driver!',
  'new_units.objects.fields.shifts.errors.shift_end': 'Choose end of shift!',
  'new_units.objects.fields.shifts.errors.shift_start': 'Choose the start of your shift!',
  'new_units.objects.fields.shifts.hide_old_shifts': 'Hide past shifts',
  'new_units.objects.fields.shifts.name': 'Name:',
  'new_units.objects.fields.shifts.no_shifts': 'Shift list is empty',
  'new_units.objects.fields.shifts.note': 'The shift usage interval overlaps with another interval.',
  'new_units.objects.fields.shifts.shift_end': 'End of shift:',
  'new_units.objects.fields.shifts.shift_start': 'Start of shift:',
  'new_units.objects.fields.shifts.start': 'WITH',
  'new_units.objects.fields.shifts.success.approved': 'The change was successfully confirmed!',
  'new_units.objects.fields.shifts.success.updated': 'The shift has been successfully updated!',
  'new_units.objects.fields.shifts.table_columns.driver': 'Driver',
  'new_units.objects.fields.shifts.table_columns.from': 'WITH',
  'new_units.objects.fields.shifts.table_columns.to': 'By',
  'new_units.objects.fields.shifts.title': 'Shifts:',
  'new_units.objects.fields.shifts.value': 'Meaning:',
  'new_units.objects.fill': 'Fill',
  'new_units.objects.no_objects': 'No objects have been added to the system',
  'new_units.objects.object': 'An object:',
  'new_units.objects.saved': 'Saved',
  'new_units.objects.search': 'Search by objects',
  'new_units.objects.search_options.custom_fields': 'Add. ',
  'new_units.objects.search_options.events': 'Events',
  'new_units.objects.search_options.groups': 'Groups',
  'new_units.objects.search_options.imei': 'IMEI',
  'new_units.objects.search_options.name': 'Name',
  'new_units.objects.search_options.phonenumber': 'Phones',
  'new_units.objects.search_options.sensors_name': 'Sensor names',
  'new_units.objects.search_options.sensors_param': 'Sensors parameter',
  'new_units.objects.search_options.sensors_type': 'Sensor types',
  'new_units.objects.success.delete': 'Objects successfully deleted!',
  'new_units.objects.table_columns.imei': 'IMEI',
  'new_units.objects.table_columns.name': 'Name',
  'new_units.objects.table_columns.protocol': 'Protocol',
  'new_units.objects.table_columns.sensors': 'Sensors',
  'new_units.objects.table_columns.sim_1_2': 'Sim 1.2',
  'new_units.objects.tabs.basic': 'Basic',
  'new_units.objects.tabs.blocked_text': 'Shifts, Maintenance and Hitches will be available after saving the object',
  'new_units.objects.tabs.couplings': 'Hitches',
  'new_units.objects.tabs.parameters': 'Those. ',
  'new_units.objects.tabs.sensors': 'Sensors',
  'new_units.objects.tabs.service': 'THAT',
  'new_units.objects.tabs.shifts': 'Shifts',
  'new_units.placeholder.select': 'Select',
  'new_units.recover.button': 'Cancel deletion',
  'new_units.recover.removed.couplings': 'Hitches removed:',
  'new_units.recover.removed.parameters': 'Removed parameters:',
  'new_units.recover.removed.sensors': 'Removed sensors:',
  'new_units.recover.removed.service': 'Removed TO:',
  'new_units.recover.removed.shifts': 'Shifts removed:',
  'new_units.recover.removed.trailers': 'Trailers removed:',
  'new_units.save_modal.close': 'Close form',
  'new_units.save_modal.continue': 'Continue',
  'new_units.save_modal.message': 'Object and data saved successfully',
  'new_units.tabs.groups': 'Groups',
  'new_units.tabs.links': 'Access links',
  'new_units.tabs.objects': 'Objects',
  'new_units.tabs.trailers': 'Trailers',
  'new_units.titles.create': 'Creating an Object',
  'new_units.titles.edit': 'Editing an object',
  'new_units.titles.groups': 'Group directory',
  'new_units.titles.links': 'Directory of access links',
  'new_units.titles.objects': 'Directory of objects',
  'new_units.titles.trailers': 'Trailer Directory',
  'new_units.trailers.additionalFields': 'Additional fields:',
  'new_units.trailers.errors.already_deleted': 'You have already deleted this trailer, if you want to return it click on the cancel button below.',
  'new_units.trailers.errors.approved': 'Please save your current trailer to create a new one!',
  'new_units.trailers.errors.name': 'Enter your name!',
  'new_units.trailers.errors.validation': 'Complete all required fields to move on to another trailer!',
  'new_units.trailers.fields.code': 'Code:',
  'new_units.trailers.fields.description': 'Description:',
  'new_units.trailers.fields.length': 'Width:',
  'new_units.trailers.fields.name': 'Name:',
  'new_units.trailers.fields.value': 'Meaning:',
  'new_units.trailers.no_trailers': 'No trailer created',
  'new_units.trailers.save': 'Save',
  'new_units.trailers.search': 'Search by trailers',
  'new_units.trailers.search_options.code': 'Code',
  'new_units.trailers.search_options.name': 'Name',
  'new_units.trailers.success.approved': 'The trailer has been successfully created!',
  'new_units.trailers.success.delete': 'Trailers have been successfully removed!',
  'new_units.trailers.success.updated': 'The trailer has been successfully updated!',
  'new_units.trailers.table_columns.code': 'Code',
  'new_units.trailers.table_columns.name': 'Name',
  'new_units.trailers.table_columns.width': 'Width',
  'new_units.trailers.title': 'Trailers:',
  'new_units.unspecified': 'not specified',
  no: 'No',
  noMatch: 'No matches found',
  no_data: 'No data',
  notification: 'Notification',
  'notification.use_speed_description': 'Activating this checkbox will not allow the driver to accelerate the object above the specified maximum speed by pressing the gas pedal when entering the geofences selected below.',
  'notification_alert.error_points': 'There are no track points!',
  'notification_alert.success': 'Event saved successfully',
  'notifications.add': 'Add notification',
  'notifications.adress': 'Address',
  'notifications.allowedDowntime': 'Allowable downtime',
  'notifications.asEvent': 'Log as events',
  'notifications.asViolation': 'Register as a violation',
  'notifications.byEmail': 'By email',
  'notifications.byPush': 'Push notifications',
  'notifications.bySms': 'By SMS',
  'notifications.byTelegram': 'On Telegram',
  'notifications.change': 'Edit notification',
  'notifications.chooseDeliveryType': 'No delivery type selected',
  'notifications.chooseEmail': 'Select at least 1 email!',
  'notifications.choosePhone': 'Choose at least 1 phone!',
  'notifications.chooseUser': 'Select at least one user',
  'notifications.connexionLost': 'Lost connection',
  'notifications.control': 'Control',
  'notifications.days_to_service': 'Number of days overdue for maintenance',
  'notifications.delivery': 'Delivery',
  'notifications.deliveryMethod': 'Delivery method',
  'notifications.deviation': 'Deviation',
  'notifications.drain': 'Drain',
  'notifications.driver': 'Driver',
  'notifications.email.placeHolder': 'Enter email',
  'notifications.enterName': 'Enter your name!',
  'notifications.enterNotifyText': 'Enter notification text!',
  'notifications.fill': 'Refueling',
  'notifications.final_fuel': 'Final fuel',
  'notifications.friday': 'Friday',
  'notifications.from': 'With',
  'notifications.geozone_name': 'Geofence name',
  'notifications.geozones.chooseVerficationType': 'Select test type!',
  'notifications.geozones.control': 'Geofence control',
  'notifications.geozones.geozones': 'Geofences',
  'notifications.geozones.verificationType': 'Type of check',
  'notifications.hs_to_service': 'Number of engine hours exceeded',
  'notifications.km_to_service': 'Number of kilometers exceeded mileage',
  'notifications.kmph': 'km/h',
  'notifications.latitude': 'Latitude',
  'notifications.longitude': 'Longitude',
  'notifications.lossDuration': 'Duration of loss',
  'notifications.lostMsg': 'Select a time interval!',
  'notifications.main': 'Basics',
  'notifications.markAsRead': 'Mark as read',
  'notifications.maxSpeed': 'Speed ​​no more',
  'notifications.min': 'min',
  'notifications.monday': 'Monday',
  'notifications.more': 'Additionally',
  'notifications.motohours': 'By engine hours',
  'notifications.name': 'Name',
  'notifications.no_sound': 'Soundless',
  'notifications.notification': 'Notifications',
  'notifications.notificationName': 'Notification title',
  'notifications.objectName': 'Object name',
  'notifications.objects': 'Objects',
  'notifications.online': 'Online',
  'notifications.paramTab': 'Parameter table:',
  'notifications.period': 'Period',
  'notifications.periodcontrol': 'Control period relative to current time',
  'notifications.phone.placeHolder': 'Enter phone...',
  'notifications.pointName': 'Point name',
  'notifications.push.placeHolder': 'Enter name or email',
  'notifications.responsetime': 'Min. ',
  'notifications.saturday': 'Saturday',
  'notifications.sensor.control': 'Sensor value monitoring',
  'notifications.sensor.controleGeo': 'Geofence control',
  'notifications.sensor.maskByName': 'Mask by name',
  'notifications.sensor.meaning': 'Meaning',
  'notifications.sensor.meaningFrom': 'Value from',
  'notifications.sensor.meaningTo': 'Value up to',
  'notifications.sensor.minimalTime': 'Min. ',
  'notifications.sensor.selectFrame': 'Choose your frames!',
  'notifications.sensor.type': 'Sensor type',
  'notifications.sensor.warning': 'Select sensor type or mask by name!',
  'notifications.sensor.warningFromTo': 'Select from or to!',
  'notifications.sensor.work': 'Trigger',
  'notifications.sensor.writeNumber': 'Enter the number',
  'notifications.sensorValue': 'Sensor value',
  'notifications.sensors': 'Sensors',
  'notifications.service.byMilage': 'By mileage',
  'notifications.service.days': 'days',
  'notifications.service.for': 'Behind',
  'notifications.service.km': 'km',
  'notifications.service.maintenance': 'Notify when scheduled maintenance is approaching',
  'notifications.service.warning': 'Enter the interval by days, mileage or engine hours!',
  'notifications.simpleTransport': 'Vehicle downtime',
  'notifications.speed': 'Speed',
  'notifications.sunday': 'Sunday',
  'notifications.telegram.placeHolder': 'Enter name or telegram',
  'notifications.template': 'Notification template',
  'notifications.temporaryValueMonitoring': 'Temporary value control',
  'notifications.text': 'Text',
  'notifications.text.arrival': 'We notify you when scheduled maintenance is approaching or overdue at the %UNIT% facility',
  'notifications.text.confirmation': 'Users who have not confirmed their email will receive an email asking them to do so. ',
  'notifications.text.connection': 'Loss of connection or coordinates %UNIT% in %TIME% near %ADDRESS% detected',
  'notifications.text.drain': '%UNIT% in %TIME% drained %DRAIN% to %LAST_FUEL% near %ADDRESS%',
  'notifications.text.events_routes': 'Trip event',
  'notifications.text.exit': '%UNIT% in %TIME% detected entering/exiting geofence at %SPEED% speed near %ADDRESS%',
  'notifications.text.filling': '%UNIT% in %TIME% refueled at %FILL% to %LAST_FUEL% near %ADDRESS%',
  'notifications.text.geozone.inside': '%UNIT% in %TIME% detected entry into geofence %GEOZONE% at %SPEED% speed near %ADDRESS%',
  'notifications.text.geozone.outside': '%UNIT% in %TIME% detected leaving geofence %GEOZONE% at %SPEED% speed near %ADDRESS%',
  'notifications.text.idle': 'Object %UNIT% at %TIME% exceeded idle time limit near %ADDRESS%',
  'notifications.text.sensor': '%UNIT% in %TIME% detected sensor activation at speed %SPEED% near %ADDRESS%',
  'notifications.text.speed': '%UNIT% at %TIME% has exceeded the maximum allowed speed limit with a value of %SPEED% near %ADDRESS%',
  'notifications.text.trip': '%UNIT% in %TIME% a trip event was recorded near %ADDRESS%',
  'notifications.textDescription': 'Enter your notification text using the custom options below. ',
  'notifications.thursday': 'Thursday',
  'notifications.time': 'Response time',
  'notifications.to': 'before',
  'notifications.tripName': 'Trip name',
  'notifications.trips.alertEvent': 'Alert Events:',
  'notifications.trips.arrCheckPoint': 'Arrival at control points',
  'notifications.trips.departure': 'Departure from checkpoints',
  'notifications.trips.end': 'End of trip',
  'notifications.trips.mask': 'Trip name mask',
  'notifications.trips.onlyViolation': 'Only in case of violation:',
  'notifications.trips.pass': 'Skip checkpoints (Strict order)',
  'notifications.trips.start': 'Start of the trip',
  'notifications.trips.validationOne': 'Please enter the service name correctly!',
  'notifications.tuesday': 'Tuesday',
  'notifications.types': 'Notification Types',
  'notifications.use_speed_limit': 'Limit the possibility of overclocking',
  'notifications.velocity.geozones': 'Geofences',
  'notifications.velocity.max': 'No more',
  'notifications.velocity.min': 'No less',
  'notifications.velocity.speed': 'Speed ​​control',
  'notifications.velocity.warning': 'Enter speed no more or less!',
  'notifications.view': 'View',
  'notifications.warning': 'enter minimum and maximum speed',
  'notifications.wednesday': 'Wednesday',
  'notifications.workType': 'Type of work',
  'notifications.service.controlService': 'Service control',
  'notifications.success.title': 'Success',
  'notifications.success.message': 'Notification added successfully',
  'notifications.success.message2': 'Notification updated successfully',
  'notifications.one.object': 'An object',
  'notifications.more.object': 'Object',
  'notifications.many.object': 'Objects',
  'notifications.commands.warning': 'You must specify at least one command for one of the protocols.',
  'notifications.commands.select_template': 'Apply template',
  'notifications.commands.send_command': 'Sending commands',
  'notifications.commands.template_empty': 'No templates found',
  'objecrRecoveryTranslater.copyObject': 'Copying an object',
  'objecrRecoveryTranslater.objectCompany': 'object in the company',
  'objecrRecoveryTranslater.restore': 'Do you really want to restore?',
  'objecrRecoveryTranslater.restoring': 'Restore',
  'objecrRecoveryTranslater.success': 'the object was successfully restored',
  object: 'An object',
  objects: 'An object',
  'objects.add.objects': 'Add objects',
  'objects.add.objects.in.group': 'Add objects to a group',
  'objects.add.units.to.group': 'Add objects to a group',
  'objects.create.edit.group': 'Editing a group',
  'objects.create.new.group': 'Create a group',
  'objects.delete': 'Removal',
  'objects.device': 'Device type',
  'objects.editImei': 'Edit selected IMEI',
  'objects.fillfield': 'It is necessary to fill in the fields in the Basic section',
  'objects.groups': 'Groups',
  'objects.groups.access': 'Group permissions',
  'objects.groups.access-title': 'Group rights',
  'objects.groups.acess': 'Access',
  'objects.groups.close-all': 'Collapse all',
  'objects.groups.objects-in-group': 'Objects in a group',
  'objects.imei': 'IMEI',
  'objects.locator.access-object': 'Access object',
  'objects.locator.copy-link': 'Link copied',
  'objects.locator.status': 'Status',
  'objects.locators': 'Access links',
  'objects.locators-date-end': 'Access end date and time',
  'objects.locators-date-select': 'Select date',
  'objects.locators-date-start': 'Access start date and time',
  'objects.locators-link': 'Link',
  'objects.name': 'Enter your name!',
  'objects.name.group': 'Group name',
  'objects.name.number': 'Name/Number',
  'objects.objects': 'Objects',
  'objects.showmonitoring': 'Show in Monitoring',
  'objects.title': 'Directory of objects',
  'objects.titleLocators': 'Directory of access links',
  'objects.titleTrailers': 'Trailer Directory',
  'objects.titlegroups': 'Group directory',
  'objects.total.group': 'Number of objects',
  'objects.trailers': 'Trailers',
  'objects.type': 'Object type',
  'objects.unit': 'Unit',
  'objects.users': 'Users',
  'objectsSensors.cansel': 'Cancel',
  'objectsSensors.clearSelect': 'Clear selection',
  'objectsSensors.copy': 'Copy',
  search_company: 'Search by objects',
  'objectsSensors.copy.text1': 'New sensors are added to the old ones. If the sensors have the same names, only the new ones are saved.',
  'objectsSensors.copy.text2': 'All sensors of the object will be deleted, and then the copied sensors will be added.',
  'objectsSensors.copy.text3': 'New sensors are added to the old ones. If among the added sensors there are names that duplicate the names of the sensors of the object itself, then a number (starting with one) is added to the names of the copied sensors.',
  objects_locator_change: 'Editing a link',
  objects_locator_create: 'Create an access link',
  objects_noPicture: 'No image selected',
  objects_phone1: 'Phone 1',
  objects_phone2: 'Phone 2',
  objects_port: 'Port:',
  objects_server: 'Data receiving server:',
  'objects_tabs.TO': 'Inspection',
  'objects_tabs.drivers.add': 'Add driver',
  'objects_tabs.drivers.change': 'Change driver',
  'objects_tabs.drivers.driver': 'Driver',
  'objects_tabs.drivers.from': 'WITH',
  'objects_tabs.drivers.to': 'By',
  'objects_tabs.extra': 'Additional',
  'objects_tabs.extra.addNewField': 'Add a new field',
  'objects_tabs.extra.changeField': 'Change field',
  'objects_tabs.extra.custom': 'Custom',
  'objects_tabs.extra.etc': 'Additionally',
  'objects_tabs.extra.fuel': 'Fuel',
  'objects_tabs.extra.motohours': 'Engine hours',
  'objects_tabs.extra.name': 'Name',
  'objects_tabs.extra.trips': 'Trips',
  'objects_tabs.extra.update_field': 'Update field',
  'objects_tabs.extra.value': 'Meaning',
  'objects_tabs.main': 'Basic',
  'objects_tabs.main.analyst': 'Analyst',
  'objects_tabs.main.analyst.error': 'Select an analyst',
  'objects_tabs.main.device.error': 'Select device type',
  'objects_tabs.main.id.error': 'Select a unique ID',
  'objects_tabs.main.name.error': 'Enter Name/Number',
  'objects_tabs.main.phone.error': 'Enter phone number',
  'objects_tabs.main.type.error': 'Select object type',
  'objects_tabs.sensor': 'Sensors',
  'objects_tabs.sensors.actions': 'Actions',
  'objects_tabs.sensors.border_bottom': 'Lower limit',
  'objects_tabs.sensors.coefficient': 'Coefficient',
  'objects_tabs.sensors.coefficientA': 'Coefficient A',
  'objects_tabs.sensors.coefficientB': 'Coefficient B',
  'objects_tabs.sensors.description': 'Description',
  'objects_tabs.sensors.enterValue': 'Please enter a value!',
  'objects_tabs.sensors.error_formula': 'Choose a formula!',
  'objects_tabs.sensors.error_param12': 'Select option 1 or option 2!',
  'objects_tabs.sensors.error_type_sensor': 'Enter sensor type!',
  'objects_tabs.sensors.formula': 'Formula',
  'objects_tabs.sensors.formula.columns': 'Column formula',
  'objects_tabs.sensors.hide_in_monitoring': 'Hide in monitoring',
  'objects_tabs.sensors.hide_in_reports': 'Hide in reports',
  'objects_tabs.sensors.keyExist': 'Such a key already exists!',
  'objects_tabs.sensors.lowerBorder': 'Lower limit X',
  'objects_tabs.sensors.option1': 'Parameter 1',
  'objects_tabs.sensors.option2': 'Parameter 2',
  'objects_tabs.sensors.selectA': 'Select the value a!',
  'objects_tabs.sensors.selectB': 'Select b!',
  'objects_tabs.sensors.selectFromValue': 'Select fromValue!',
  'objects_tabs.sensors.selectValueX': 'Select x value!',
  'objects_tabs.sensors.selectValueY': 'Select value!',
  'objects_tabs.sensors.sensors_create': 'Creating a sensor',
  'objects_tabs.sensors.sensors_edit': 'Editing a sensor',
  'objects_tabs.sensors.show_graph': 'Show graph',
  'objects_tabs.sensors.show_table': 'Show table',
  'objects_tabs.sensors.smoothing': 'Smoothing',
  'objects_tabs.sensors.type_sensor': 'Sensor type',
  'objects_tabs.sensors.units': 'Unit of measurement',
  'objects_tabs.sensors.upperBorder': 'Upper limit X',
  'objects_tabs.sensors.valueX': 'X value',
  'objects_tabs.sensors.valueY': 'Y value',
  'objects_tabs.services.active_on_interval_days': 'Activate interval in days',
  'objects_tabs.services.active_on_interval_mileage': 'Activate mileage interval',
  'objects_tabs.services.create_form': 'Creation of maintenance',
  'objects_tabs.services.date.placeholder': 'Enter the date!',
  'objects_tabs.services.description': 'Description',
  'objects_tabs.services.edit_form': 'Editing TO',
  'objects_tabs.services.intervalInDays': 'Enter the interval in days!',
  'objects_tabs.services.intervalInMotoHours': 'Activate engine hour interval',
  'objects_tabs.services.intervalInMotoHoursDate': 'Interval by engine hours, day/hours',
  'objects_tabs.services.interval_days': 'Days',
  'objects_tabs.services.interval_days_numb_day': 'Interval in days, day/days',
  'objects_tabs.services.interval_mileage': 'Mileage',
  'objects_tabs.services.interval_mileage_numb_km': 'Mileage interval, number/km',
  'objects_tabs.services.lastTime': 'Enter mileage',
  'objects_tabs.services.lastTimeDate': 'Last time, date/hour',
  'objects_tabs.services.lastTimeDate.warning': 'Enter last time!',
  'objects_tabs.services.last_one_date': 'Last time, date',
  'objects_tabs.services.last_one_numb_km': 'Last time, number/km',
  'objects_tabs.services.mileageInterval': 'Enter your current mileage reading',
  'objects_tabs.services.name_service': 'Service',
  'objects_tabs.services.warning.date': 'Enter the date!',
  'objects_tabs.services.warning.description': 'Enter a description!',
  'objects_tabs.services.warning.name': 'Enter service names!',
  'objects_tabs.trailer': 'Trailer',
  'objects_tabs.trailer.add': '"Add trailer',
  'objects_tabs.trailer.change': 'Change trailer',
  'objects_tabs.trailers': 'Trailers',
  objects_trailer_change: 'Change trailer',
  objects_trailer_create: 'Create a trailer',
  objects_trailer_key: 'Key',
  objects_trailer_moreFields: 'Additional fields',
  one_element_deleted: '1 item removed',
  'objects.generate.imei': 'Generate virtual IMEI',
  outdated_version: 'Version is outdated',
  outdated_version_message: 'Refresh the page',
  pdf: 'PDF',
  phone: 'Telephone',
  phone_confirmation: 'Phone verification',
  'pixels.group': 'screen points',
  'qrAdminTranslater.addGps': 'Adding a GPS tracker',
  'qrAdminTranslater.print': 'Seal',
  'qrAdminTranslater.qrCreate': 'Creation QR code',
  'qrAdminTranslater.register': 'Registration and',
  'qrAdminTranslater.scanCode': `Scan the code to register a GPS tracker or enter the code at ${currentUrl}/c`,
  'report.tables.tabs.add_mask': 'Add a mask by sensor name',
  'report.tables.tabs.add_parameter': 'Add parameter',
  'report.tables.tabs.enter_value': 'Enter value',
  'report.tables.tabs.name_mask': 'Sensor name mask',
  'report.tables.tabs.parameter': 'Parameter',
  'report.tables.tabs.parameters': 'Options',
  'report.tables.tabs/parameter_hint': 'Select the names of the parameters that will be involved in generating the report.',
  'reportEdit.sensor.addTotalValue': 'Add total value in report:',
  'reportEdit.sensor.hintText': 'Add the resulting value in the totals row for this column in the generated report.',
  reports: 'Reports',
  'reports,tables.fields.mask_name_sensor': 'Fuel sensor name mask',
  'reports.action': 'Actions',
  'reports.add-group-report': 'Add to template',
  'reports.add-in-template': 'Add to template',
  'reports.add-new-template': 'Add a new template',
  'reports.add-table-to-reports': 'Add a table from a template',
  'reports.applied_filter': 'Filtering from Monitoring has been applied.',
  'reports.attached': 'File attached',
  'reports.base-table': 'Base table',
  'reports.build-report': 'Build',
  'reports.chart.fillings': 'Gas stations',
  'reports.chart.fillings_markers': 'Refueling intervals',
  'reports.chart.intervals': 'Intervals',
  'reports.chart.thefts': 'Plums',
  'reports.chart.thefts_markers': 'Drain intervals',
  'reports.column': 'Column',
  'reports.composite_report': 'Composite report',
  'reports.connect': 'Link to chart',
  'reports.contains': 'contains',
  'reports.copy': 'Copy',
  'reports.copy_key': 'Copy the key into the Excel template cell, and then load the template into SKIF.PRO',
  'reports.create_template': 'Create a template',
  'reports.deleted_intervals_messages': 'Deleted message intervals',
  'reports.deleted_messaged': 'Deleted messages',
  'reports.display_all_events': 'Show all events',
  'reports.download_selected': 'Load selected',
  'reports.edit-report': 'Edit',
  'reports.edit-reports': 'Editing report parameters',
  'reports.editTemplate.nameTemplate': 'Template name',
  'reports.edit_template': 'Change template',
  'reports.enter_name': 'Enter your name!',
  'reports.exclude_interval': 'Eliminate interval',
  'reports.form_for_unloading': 'Upload form (excel template)',
  'reports.from_monitoring': 'from Monitoring',
  'reports.group-object': 'Group of objects',
  'reports.group-table': 'Table group',
  'reports.increase': 'Increase',
  'reports.interval': 'Interval',
  'reports.interval_for_deletion': 'Interval for deleting messages',
  'reports.interval_info': 'Deleted message intervals are not displayed in the log and do not participate in generating reports.',
  'reports.is-already-template': 'Existing',
  'reports.key_hint': 'Create a report template in Excel, then place the data into it by copying the appropriate keys. ',
  'reports.link_to_magazine': 'Link to magazine',
  'reports.list_key': 'List of keys for Excel template',
  'reports.load-report': 'Download',
  'reports.map_note': 'Click to see the point on the map',
  'reports.map_snapshot': 'Map snapshot',
  'reports.menu': 'Menu',
  'reports.mini_hint': 'After copying, select the Excel template cell and press CTRL+V.',
  'reports.moving': 'Moving',
  'reports.name-template': 'Template name',
  'reports.new-temlate': 'New',
  'reports.not-select': 'Not selected',
  'reports.not_all_objects_displayed': 'Not all objects are displayed.',
  'reports.object': 'Object',
  'reports.onlyResult': 'Show totals only',
  'reports.operation': 'Operation',
  'reports.period': 'Period',
  'reports.print-report': 'Seal',
  'reports.reduce': 'Decrease',
  'reports.reports-size-screen.maximum': 'Full screen',
  'reports.reports-size-screen.medium': '1/2',
  'reports.reports-size-screen.minimum': '1/4',
  'reports.reports-size-screen.thirdScreen': '1/3',
  'reports.reports-template': 'Editing a template',
  'reports.have-period-from-report': 'The period from the report is applied. ',
  'reports.today': 'Today',
  'reports.yesterday': 'Yesterday',
  'reports.week': 'This week',
  'reports.month': 'This month',
  'reports.reports-visit-geozone': 'Geofence visit report',
  'reports.reset_magnification': 'Reset magnification',
  'reports.select': 'Choice',
  'reports.select-group-object': 'Selecting a group of objects',
  'reports.select-object': 'Selecting a vehicle',
  'reports.select-table': 'Table selection',
  'reports.select-template': 'Selecting a table template',
  'reports.select_object': 'Select an object!',
  'reports.select_objects_group': 'Select a group of objects!',
  'reports.select_with_magnification': 'Zoom selection',
  'reports.sensor_placeholder': 'Sensor mask',
  'reports.show-chart': 'Schedule',
  'reports.show-color': 'Track color',
  'reports.show_all_objects': 'Show all objects',
  'reports.show_annotation_on_click': 'Annotation by clicking on a line',
  'reports.show_annotation_on_click_hint': 'If activated, when you click on a report line, an annotation on the event is displayed.',
  'reports.show_key': 'View the list of keys for the Excel template',
  'reports.success_copy_template': 'The template has been successfully copied.',
  'reports.tab-columns': 'Columns',
  'reports.tab-geozones': 'Geofences',
  'reports.tab-main': 'General',
  'reports.tab-main.group': 'Grouping',
  'reports.tab-main.group-field': 'Group by field',
  'reports.tab-main.interval-priority': 'Report interval priority',
  'reports.tab-main.interval-priority-tooltip': 'If this setting is active and the interval is cut by the beginning or end of the Shift, then it is included in the report in its entirety.<br>If this setting is not active, then the exact Shift interval will appear in the report.<br>For example, if the shift starts at 08:00, and the trip began at 06:00,<br>then if the “Priority of report intervals” setting is active, the interval in the Trips report will start at 06:00,<br>and if the “Report intervals priority” setting is not active - then at 08:00.',
  'reports.tab-main.show-total': 'Show total',
  'reports.table.type': 'Table Type',
  'reports.tables': 'Tables',
  'reports.tables.add_mask_sensor': 'Add a mask by sensor name',
  'reports.tables.columns.fields.column': 'Column',
  'reports.tables.columns.fields.filter': 'Filter',
  'reports.tables.columns.fields.max_value': 'Max. ',
  'reports.tables.columns.fields.min_value': 'Min. ',
  'reports.tables.columns.fields.name.error': 'Enter a name',
  'reports.tables.columns.fields.name.label': 'Column name',
  'reports.tables.columns.fields.visibility': 'Visibility',
  'reports.tables.fields.add_mask': 'Add a mask by sensor name',
  'reports.tables.fields.excel_template_name': 'Excel Template Name',
  'reports.tables.fields.files_warn': 'You can only upload files in xlsx xlsm xlsb formats',
  'reports.tables.fields.geozone_warn': 'You must select at least one entry geofence and one exit geofence.',
  'reports.tables.fields.grouping': 'Grouping',
  'reports.tables.fields.grouping_by_field': 'Group by field',
  'reports.tables.fields.name.error': 'Enter your name!',
  'reports.tables.fields.name.label': 'Name',
  'reports.tables.fields.sensor_name_mask': 'Sensor name mask',
  'reports.tables.fields.showThefts': 'Show refills',
  'reports.tables.fields.showfillings': 'Show plums',
  'reports.tables.fields.template_upload': 'Download',
  'reports.tables.geozones.enter': 'Entrance',
  'reports.tables.geozones.exit': 'Exit',
  'reports.tables.geozones.title': 'Geofences',
  'reports.tables.report': 'Report',
  'reports.tables.tabs.additional_columns': 'Add. ',
  'reports.tables.tabs.all_protocol': 'All protocols',
  'reports.tables.tabs.columns': 'Columns',
  'reports.tables.tabs.data': 'Data',
  'reports.tables.tabs.general': 'General',
  'reports.tables.tabs.geozones': 'Geofences',
  'reports.tables.tabs.hint': 'In closed reports, the user cannot change the sensors and parameters by which it is built.',
  'reports.tables.tabs.make_close_report': 'Make the report private',
  'reports.tables.tabs.mask_sensor': 'Sensor masks',
  'reports.tables.tabs.mask_sensor_hint': 'To generate a report, select those object sensors that will satisfy at least one of the specified masks (read more at',
  'reports.tables.tabs.parameters': 'Select an option',
  'reports.tables.tabs.sensors': 'Sensors',
  'reports.tables.tabs.table_name': 'Name:',
  'reports.tables.tabs.type_table': 'Table type:',
  'reports.template': 'Sample',
  'reports.templateWarning': 'Enter a template name!',
  'reports.template_deletion': 'Deleting a template',
  'reports.template_deletion_sure': 'Are you sure you want to delete the report template?',
  'reports.value': 'Meaning',
  'reports.was-deleted': 'was deleted',
  'reports.without_grouping': 'No grouping',
  'reportsFilter.addInterval': 'Add interval',
  'reportsFilter.daysWeek': 'Days of the week:',
  'reportsFilter.filterByTime': 'Filtering by time intervals:',
  'reportsFilter.filterByGeozone': 'Filtering by geofences',
  'reportsFilter.hint.in': 'Inside geofences',
  'reportsFilter.hint.out': 'Outside geofences',
  'reportsFilter.hint.in.text': 'The report displays only the data that was received in the selected geozones.',
  'reportsFilter.hint.out.text': 'The report displays only the data that was received outside the selected geofences.',
  'reportsFilter.filterType': 'Filtration type:',
  'reportsFilter.filterHintFunction': 'If the function is enabled, a state (for example, a trip) that started inside the interval but ended outside it will be truncated. ',
  'reportsFilter.filterHintSelectInterval': 'Select the time intervals by which the data in the report will be filtered.',
  'reportsFilter.title': 'Filtration',
  'reportsFilter.trimmerInterval': 'Trim intervals:',
  'reportsFilter.zeroInterval': 'Zero interval!',
  'reportsFilter.groupBy': 'Groups',
  'reportsFilter.addGroup': 'Add grouping',
  'reportsFilter.byGroup.tooltip': 'At the moment, you can add no more than three simultaneous groups',
  'reportsFilter.byGroup.noSearch.msg': 'No groups found',
  'reportsTemplate.successUpdate.msg': 'Template saved successfully',
  'reports.loadingMoreData.msg': 'Receiving data',
  'reportsTranslater.noData': 'There is no data to build a report',
  'reportsTranslater.rewrite': 'Request',
  'response-terminal': 'Answer',
  'retranslatorTranlsater.changeIMEI': 'Replace with IMEI',
  'retranslatorTranlsater.gps_signal_category': 'GPS signal category:',
  'retranslatorTranlsater.hint': 'Slow used for public transport trackers',
  'retranslatorTranlsater.participant': 'Program participant ID:',
  'retranslatorTranlsater.port': 'Relay port',
  'retranslatorTranlsater.protocol': 'Protocol',
  'retranslatorTranlsater.retranslationFrom': 'Rebroadcast from',
  'retranslatorTranlsater.retranslationTo': 'Relay to',
  'retranslatorTranlsater.route_identifier': 'Route ID:',
  'retranslatorTranlsater.serverRetranslation': 'Relay server',
  'retranslatorTranlsater.type_vehicle': 'Type of public vehicle:',
  role: 'Role',
  'routesTrips.addTrip': 'Add trip',
  'routesTrips.editTrip': 'Edit trip',
  'routesTrips.name': 'Name',
  'routesTrips.route.addFromGeozones': 'Add geofences',
  'routesTrips.route.addFromGeozones.title': 'Add geofences',
  'routesTrips.route.addFromMap': 'Add a point from the map',
  'routesTrips.route.create': 'Create route',
  'routesTrips.route.createGeozone.figure': 'Figure',
  'routesTrips.route.createGeozone.name': 'Name',
  'routesTrips.route.createGeozone.title': 'Add a point from the map',
  'routesTrips.route.createGeozone.width': 'Width',
  'routesTrips.route.edit': 'Edit route',
  'routesTrips.route.name.placeholder': 'Route name',
  'routesTrips.route.point': 'Dot',
  'routesTrips.routes': 'Routes',
  'routesTrips.title': 'Routes and trips',
  'routesTrips.trip.activationType': 'Activation type',
  'routesTrips.trip.activationType.tooltip': 'If “at the time of the first point” is selected, then the start of the flight is fixed strictly according to the time specified at the first control point. ',
  'routesTrips.trip.addDays': 'Add a day to subsequent points',
  'routesTrips.trip.addFromGeozones': 'Add geofences',
  'routesTrips.trip.addFromGeozones.title': 'Add geofences',
  'routesTrips.trip.addFromMap': 'Add a point from the map',
  'routesTrips.trip.addFromRoutes': 'Add waypoints',
  'routesTrips.trip.addFromRoutes.title': 'ADD ROUTE POINTS',
  'routesTrips.trip.arrival': 'Arrival',
  'routesTrips.trip.arrivalDev': 'Arrival Rejection',
  'routesTrips.trip.clearDays': 'Clear additional ',
  'routesTrips.trip.createGeozone.figure': 'Figure',
  'routesTrips.trip.createGeozone.name': 'Name',
  'routesTrips.trip.createGeozone.title': 'Add a point from the map',
  'routesTrips.trip.date.cancel_days_period': 'Exclude holidays',
  'routesTrips.trip.date.filter_days': 'Filter days',
  'routesTrips.trip.date.placeholder': 'Select dates',
  'routesTrips.trip.date.reset_days_period': 'Restore period days',
  'routesTrips.trip.date.set_days_period': 'Filter days of the period',
  'routesTrips.trip.date.total_days_period': 'Total days of the period',
  'routesTrips.trip.date.week_days.all_days': 'All days',
  'routesTrips.trip.date.week_days.even': 'Even',
  'routesTrips.trip.date.week_days.friday': 'PT',
  'routesTrips.trip.date.week_days.monday': 'Mon',
  'routesTrips.trip.date.week_days.odd': 'Not even',
  'routesTrips.trip.date.week_days.saturday': 'SB',
  'routesTrips.trip.date.week_days.sunday': 'Sun',
  'routesTrips.trip.date.week_days.thursday': 'Thu',
  'routesTrips.trip.date.week_days.tuesday': 'VT',
  'routesTrips.trip.date.week_days.wednesday': 'SR',
  'routesTrips.trip.departure': 'Departure',
  'routesTrips.trip.departureDev': 'Item Rejection',
  'routesTrips.trip.driver': 'Driver',
  'routesTrips.trip.editGeozone.figure': 'Figure',
  'routesTrips.trip.editGeozone.name': 'Name',
  'routesTrips.trip.editGeozone.title': 'Editing a geofence',
  'routesTrips.trip.entranceToFirstPoint': 'Upon entering the first point',
  'routesTrips.trip.firstPoint': 'According to the time of the first point',
  'routesTrips.trip.leavingToFirstPoint': 'Upon leaving the first point',
  'routesTrips.trip.name': 'Name*',
  'routesTrips.trip.name.placeholder': 'Trip name...',
  'routesTrips.trip.object': 'Object*',
  'routesTrips.trip.object.placeholder': 'Object',
  'routesTrips.trip.order': 'Passage order',
  'routesTrips.trip.order.arbitrary': 'Arbitrary',
  'routesTrips.trip.order.strict': 'Strict',
  'routesTrips.trip.period': 'Period',
  'routesTrips.trip.point': 'Dot',
  'routesTrips.trip.pointsValidation': 'Select at least two points',
  'routesTrips.trip.removeDays': 'Delete days to subsequent points',
  'routesTrips.trip.trailer': 'Trailer',
  'routesTrips.trip.validation': 'This field is required',
  'routesTrips.trip.validation.total_period': 'You must add at least one total day',
  'routesTrips.trip.workType': 'Type of work',
  'routesTrips.trip.work_type_three': 'Type of work 3',
  'routesTrips.trip.work_type_two': 'Type of work 2',
  'routesTrips.trips': 'Trips',
  save: 'Save',
  search: 'Search',
  search_company: 'Search by objects',
  select: 'Choose',
  select_all: 'Select all',
  send: 'Send',
  'send-terminal': 'Dispatch',
  'sensorNotificationTranslater.inFrame': 'Within established limits',
  'sensorNotificationTranslater.outFrame': 'Outside the established framework',
  'sensors_details.choose': 'Choose',
  'sensors_details.help_validator': 'The validator will help you filter or convert sensor values',
  'sensors_details.sensor_validator': 'Sensor validator:',
  'sensors_details.validation_info': 'The monitoring will not display the sensor value if there has been no valid data for more than 3 days.',
  'sensors_details.validation_principle': 'Validation principle:',
  'sessions.day': 'Day',
  'sessions.event': 'Event',
  'sessions.ip': 'IP address',
  'sessions.month': 'Month',
  'sessions.time': 'Date/Time',
  'sessions.title': 'Sessions',
  'sessions.user': 'User',
  'sessions.week': 'Week',
  'setting-company': 'Company Settings',
  'setting-user': 'User Settings',
  'settings.balance.company': 'Company:',
  'settings.balance.currency': 'rub',
  'settings.balance.errors.amount': 'Enter the top-up amount',
  'settings.balance.min_amount': 'Minimum payment amount',
  'settings.balance.mir_unavailable': 'MIR maps are temporarily unavailable for saving',
  'settings.balance.save_card': 'Save map',
  'settings.balance.top_up': 'Top up',
  'settings.balance.top_up_amount': 'Top up amount',
  'settings.balance.top_up_title': 'Replenishment',
  'settings.balance.turn_on_autopay': 'Enable auto payment',
  'settings.company.data_format': 'Date format:',
  'settings.company.exit_time': 'Exit after n minutes of inactivity',
  'settings.company.exit_time_tooltip': '0 or empty field - there will be no exit due to inaction',
  'settings.company.high_security_mode': 'Enhanced Security Mode',
  'settings.company.high_security_mode_hint.first': 'To increase security, new users will be required to create strong passwords when activating this mode. ',
  'settings.company.high_security_mode_hint.second': "When changing a user's password, they must log in using the administrator password and then set their own password for the account. ",
  'settings.company.integrator': 'Integrator:',
  'settings.company.last_widget_time': 'Time of the last date in the widget:',
  'settings.company.name': 'Name:',
  'settings.company.note': 'Specify which time format will be used when selecting the last date in a date range (in the date selection widget).',
  'settings.company.retransmit_efis': 'Rebroadcast to EFIS',
  'settings.company.success.update_company': 'Company settings have been successfully updated',
  'settings.company.time_format': 'Time format:',
  'settings.company.time_zone': 'Time zone:',
  'settings.company.title': 'Company Settings',
  'settings.display.area_measurement': 'Area units:',
  'settings.display.geozones': 'GEOZONES',
  'settings.display.group_by_dots': 'Group if distance is less than (screen pixels):',
  'settings.display.hectar': 'ha',
  'settings.display.hide_events': 'Hide events on the map:',
  'settings.display.hide_notification': 'Hide notifications on map:',
  'settings.display.kilometer': 'km',
  'settings.display.map': 'Map',
  'settings.display.meter': 'm',
  'settings.display.monitoring': 'Monitoring',
  'settings.display.notification': 'Notifications',
  'settings.display.object_grouping': 'Grouping objects on the map:',
  'settings.display.show_annotation': 'Show annotation when clicking on an object in the monitoring list:',
  'settings.display.show_geozones': 'Show geofences on the map:',
  'settings.display.group_on_map': 'Group on map:',
  'settings.display.title': 'Display Settings',
  'settings.display.track_weight': 'Track thickness on the map',
  'settings.invite.access_level': 'Access level:',
  'settings.invite.errors.access_level': 'Select access level',
  'settings.invite.errors.user_email': 'Enter email',
  'settings.invite.submit_button': 'Invite',
  'settings.invite.success.invite': 'The user was successfully invited',
  'settings.invite.title': 'Invite user',
  'settings.invite.user_email': 'User email:',
  'settings.invite.user_email_placeholder': 'Enter your email',
  'settings.main.access_settings': 'Access settings',
  'settings.main.add_balance': 'Top up balance',
  'settings.main.additional': 'Additionally:',
  'settings.main.company': 'Company:',
  'settings.main.company_settings': 'Company Settings',
  'settings.main.company_tariff': 'Company tariff',
  'settings.main.display_settings': 'Display Settings',
  'settings.main.exit': 'Log out',
  'settings.main.finance_and_balance': 'Finance and balance:',
  'settings.main.financical_report': 'Financial report',
  'settings.main.go_admin_panel': 'Go to admin. ',
  'settings.main.go_admin_panel_beta': 'Admin. ',
  'settings.main.go_back': 'Return',
  'settings.main.invite_user': 'Invite user',
  'settings.main.news_and_updates': 'News and updates',
  'settings.main.payments_history': 'Payment history',
  'settings.main.reference': 'Help (Wiki)',
  'settings.main.sessions': 'Sessions',
  'settings.main.support': 'Support',
  'settings.main.work_with_company': 'Working with the company:',
  'settings.support.contacts.email': 'E-mail: monitoringplus@support.ru',
  'settings.support.contacts.phone': 'Phone (only on weekdays from 9:00 to 18:00): 89008887712',
  'settings.support.contacts.telegram': 'Telegram: @Monitoring_plus',
  'settings.support.description': 'Good afternoon ',
  'settings.support.title': 'Support',
  'settings.tariff.accounting_type': 'Accounting type:',
  'settings.tariff.active_autopay': 'Autopayment activated',
  'settings.tariff.autopay': 'Auto payment:',
  'settings.tariff.by_number_objects': 'By number of objects',
  'settings.tariff.by_number_objects_sent_data': 'By the number of objects sending data',
  'settings.tariff.card': 'Map:',
  'settings.tariff.card_section.add': 'Adding a map',
  'settings.tariff.card_section.add_note': 'To add an autopayment card, we will need to debit 1 ruble from you. ',
  'settings.tariff.card_section.change': 'Changing a card for auto payment',
  'settings.tariff.card_section.continue': 'Continue',
  'settings.tariff.card_section.errors.mir_error': 'Autopayment is temporarily unavailable with MIR cards',
  'settings.tariff.card_section.mir_system': 'MIR system',
  'settings.tariff.card_section.select_system': '*Select payment system:',
  'settings.tariff.card_section.temporarily_unavailable': 'Temporarily unavailable',
  'settings.tariff.card_section.visa_master': 'Visa or Mastercard',
  'settings.tariff.errors.autopay': 'The auto payment feature has been disabled because there are not enough funds on the card to complete the transaction.',
  'settings.tariff.last_period_sum': 'Amount for the previous period',
  'settings.tariff.next_write_off': 'The following write-off:',
  'settings.tariff.no_card_added': 'Map not added',
  'settings.tariff.object_count': 'Max. ',
  'settings.tariff.object_price': 'Cost of 1 object:',
  'settings.tariff.on_balance': 'On account (balance):',
  'settings.tariff.payment_period': 'Payment period:',
  'settings.tariff.period_start': 'Start of the current period:',
  'settings.tariff.success.delete_card': 'Card deleted successfully',
  'settings.tariff.success.update_autopay': 'Autopayment updated successfully',
  'settings.tariff.title': 'Tariff information',
  'settings.tariff.turn_on_autopay': 'Enable auto payment',
  'settings.user.access_level': 'Access level:',
  'settings.user.change_access_level': 'Change access level',
  'settings.user.change_password': 'Change password',
  'settings.user.code': 'Code:',
  'settings.user.description': 'Description:',
  'settings.user.edit': 'Editing',
  'settings.user.email': 'Email:',
  'settings.user.errors.admin_strong_pass': 'The password must be between 15 and 25 characters and consist of at least one uppercase English letter, at least one lowercase English letter, and at least one special character (~ ! @ # $ % ^ & * ( ) - = + [ "{ ] "  < . > / ?)',
  'settings.user.errors.email': 'Enter Email',
  'settings.user.errors.name': 'Enter name',
  'settings.user.errors.phone_confirmation': 'Phone number not verified.',
  'settings.user.errors.simple_pass': 'The password must be between 5 and 25 characters and contain at least one English letter and at least one number',
  'settings.user.errors.user_strong_pass': 'The password must be between 8 and 25 characters and consist of at least one uppercase English letter, at least one lowercase English letter, and at least one special character (~ ! @ # $ % ^ & * ( ) - = + [ "{ ] "  < . > / ?)',
  'settings.user.language': 'Language:',
  'settings.user.mailing_id': 'Mailing IDs for Telegram:',
  'settings.user.name': 'Name:',
  'settings.user.note.first': '1) Log in to the telegram bot: t.me/skif2_bot',
  'settings.user.note.second': '2) Click on Start, the bot returns the mailing ID for Telegram',
  'settings.user.phone': 'Telephone:',
  'settings.user.set_password': 'Set a password',
  'settings.user.success.avatar_change': 'The avatar was successfully updated',
  'settings.user.success.change_password': 'The password has been successfully changed',
  'settings.user.success.update_info': 'Information updated successfully',
  'settings.user.user': 'User:',
  'status-terminal': 'Status',
  success: 'Success',
  'tariff.accept': 'Agree',
  'tariff.amount': 'Account amount',
  'tariff.amounttopay': 'Sum',
  'tariff.balance': 'On account:',
  'tariff.blocked-info': "There are insufficient funds in the Company's account. ",
  'tariff.choose': 'Selecting a tariff plan',
  'tariff.choose-pay': 'CHOOSE AND PAY THE TARIFF',
  'tariff.choose-units': 'Select the required number of objects:',
  'tariff.company-blocked': 'the company is blocked',
  'tariff.currency': 'shaft',
  'tariff.currency-month': 'currency/month',
  'tariff.currency-type': 'currencies',
  'tariff.current': 'Your current tariff:',
  'tariff.date-time': 'Date/Time',
  'tariff.days': 'days',
  'tariff.info-one': 'The cost of 1 object in the SKIF PRO system is',
  'tariff.info-two--part-one': 'on',
  'tariff.info-two--part-two': 'calendar days.',
  'tariff.information': 'TARIFF INFORMATION',
  'tariff.kz': 'KZT',
  'tariff.less_than_four_days': 'day',
  'tariff.next-payment': 'The following write-off:',
  'tariff.notselected': 'Not selected',
  'tariff.objects': 'Objects',
  'tariff.offers': 'offers.',
  'tariff.one_day': 'day',
  'tariff.operation': 'Operation',
  'tariff.paid': 'Paid period',
  'tariff.pay': 'PAY THE TARIFF',
  'tariff.payableto': 'for payment:',
  'tariff.payement-date': 'Payment:',
  'tariff.payment-history': 'HISTORY OF CHARGES AND PAYMENTS',
  'tariff.payments-history': 'Payment history',
  'tariff.price': 'Tariff cost:',
  'tariff.remaining': 'Left:',
  'tariff.rouble': 'rub',
  'tariff.tariffprice': 'Tariff cost:',
  'tariff.trial': 'Trial period:',
  'tariff.txt-five': 'you need to contact support.',
  'tariff.txt-four': 'If you want to reduce the tariff, you will',
  'tariff.txt-one': 'The use of the SKIF PRO monitoring system is paid.',
  'tariff.txt-seven': 'tariff, payment rules and',
  'tariff.txt-six': 'By clicking the “Agree” button you agree to',
  'tariff.txt-three': 'The first 7 days are free.',
  'tariff.txt-two': 'You can choose the Tariff required for your company.',
  'tariff.units': 'objects',
  'templateCommandTranslater.add': 'Adding a Command Template',
  'templateCommandTranslater.edit': 'Editing a Command Template',
  'templateCommandTranslater.enterNameCommand': 'Enter team name',
  'templateCommandTranslater.enterTextCommand': 'Enter command text',
  'templateCommandTranslater.nameCommand': 'Team name',
  'templateCommandTranslater.parametrsCommand': 'Command Options',
  'templateCommandTranslater.selectCommand': 'SELECT TEAM',
  'templateCommandTranslater.selectTypeTerminal': 'Select terminal type',
  'templateCommandTranslater.textCommand': 'Command text',
  'templateCommandTranslater.typeTermial': 'Terminal type',
  'text-command-terminal': 'Command text',
  time: 'Time',
  to: 'On',
  'tools.admin_panel_beta': 'βeta test',
  'tools.admin_panel_v2': 'Admin. ',
  'tracking.speed.legend.text': 'Speed ​​gradation',
  'tracking.speed.legend.mileage': 'Mileage',
  'tracking.speed.legend.km': 'km.',
  'trailer.width': 'trailer width (meter)',
  'translation.key': 'value',
  type: 'Type',
  'units.create': 'Creating an Object',
  'units.update': 'Editing an object',
  'unitsVisiblityTranslater.filterFormMonitoring': 'Filtering from Monitoring has been applied.',
  'unitsVisiblityTranslater.notShowAllObject': 'Not all objects are displayed.',
  'unitsVisiblityTranslater.showAllObject': 'Show all objects',
  'user.create_user': 'Creating a user',
  'user.edit_user': 'Editing a user',
  'user.enter_language': 'Enter your language!',
  'user.history_change': 'History of changes',
  'user.invite_all': 'Invite to all companies',
  'user.invite_email': 'Email',
  'user.invite_role': 'Role',
  'user.invite_role_error': 'Enter role!',
  'user.invite_user': 'Invite',
  'users.approve_close_form': 'Changes were not saved. ',
  'users.change_password': 'Change',
  'users.change_password_title': 'Change password',
  'users.close_form': 'Close profile',
  'users.confirm': 'Confirm',
  'users.confirm_link': 'A message with a link will be sent. ',
  'users.confirm_message': 'a letter will be sent asking you to confirm your consent to receive information messages from the SKIF.PRO system.',
  'users.confirm_notification': 'The user will not receive email notifications until the email is confirmed.',
  'users.current_password': 'Current Password',
  'users.email': 'Mail',
  'users.email_placeholder': 'Enter your email',
  'users.enter': 'Entrance',
  'users.exit': 'Exit',
  'users.hint.lang': 'Select language',
  'users.hint.role': 'Select user role',
  'users.invalid_email': 'Email not confirmed.',
  'users.invalid_phone': 'Phone number not verified.',
  'users.invite': 'Invite',
  'users.invite_all': 'Invite to all companies',
  'users.invite_level_access': 'Access level',
  'users.isDriver': 'Driver',
  'users.name': 'Name',
  'users.password_empty': 'If you leave the field empty, you will receive confirmation of your password change by email.',
  'users.sessions': 'Sessions',
  'users.subscribe': 'Invite user',
  'users.success_email_send': 'The letter was sent successfully.',
  'users.success_message_send': 'Message sent successfully.',
  'valid.text': 'Fill in the field',
  value: 'Meaning',
  word: 'Word',
  'work_company.create_object': 'Creating Objects',
  'work_company.data': 'Data from the terminal',
  'work_company.data_terminal.ste_3': 'In the Log report you can also add parameters and other sensors. ',
  'work_company.data_terminal.step_1': 'You can view unprocessed “raw” data from terminals in the Log report. ',
  'work_company.data_terminal.step_2': 'Then, in the window that opens, you need to select an object, period and click on the log in the list of reports',
  'work_company.short_list.action_eight': 'When selecting parameters, you can customize them, to do this, click on the gear next to the desired parameter, enter the required values ​​and click save',
  'work_company.short_list.action_five': 'Note: After selecting the desired terminal type, pay attention to the prompt indicating the server and port where you need to configure the terminals to send data to the server',
  'work_company.short_list.action_four': 'In the form that opens, fill out all the fields and select an image for the object',
  'work_company.short_list.action_nine': 'After adding and saving the sensor, click create object',
  'work_company.short_list.action_seven': 'Next, you need to click on “+”, a window for adding a sensor will open, in which you need to fill in all the fields, select parameters and formulas',
  'work_company.short_list.action_six': 'To add a sensor to an object, select “Sensors” from the top drop-down list',
  'work_company.short_list.action_three': 'Click add',
  'work_company.short_list.action_two': 'To go to the Admin Panel from any Company, click on the button with the user name, and then on the “Admin Panel” item. In the selected company, go to the object directory',
  'work_company.title': 'Company workplace',
  'terminal.blocked': 'The terminal is blocked',
  yes: 'Yes',
  'archive.title_settings': 'Settings',
  'archive.title_events': 'Events by object',
  'archive.title': 'State archive',
  'archive.subtitle': 'Object',
  'archive.search': 'Search',
  'archive.view': 'Display',
  'archive.add_state': 'Add state',
  'archive.state_name': 'State name',
  'archive.state_protocol': 'Protocol',
  'archive.state_date': 'Active since',
  'archive.state_imei': 'IMEI',
  'archive.current': 'Current',
  'archive.add_button': 'Add',
  'archive.zebra': 'Zebra',
  'archive.state_edit': 'Edit',
  'archive.state_copy': 'Copy and make current',
  'archive.state_delete': 'Delete',
  'archive.state_warning': 'You are editing the archived state of an object.',
  'archive.state_save': 'Save changes',
  'archive.state_cancel': 'Cancel',
  'archives.groups_report': 'Group report',
  'archives.warning': 'Some objects have several archive states for the selected period, so they cannot participate in the generation of a group report and have been excluded.',
  'archives.exclude': 'Excluded from group report',
  'archives.requestReport': 'Request report',
  'trackColors.export': 'Export',
  'trackColors.limits': '7 speed levels available',
  'trackColors.title': 'Track color',
  'trackColors.to': 'to ',
  'trackColors.selected': 'Selected: ',
  'archives.object.haveState': 'The current object contains states',
  'request.error.504': 'We were unable to process the request within an acceptable time frame. ',
  'mailing.tooltip': 'The date and time of sending the first message will be calculated depending on the selected period and the date of the last sending.',
  'helper.monitoring.text_one': 'Monitor the current status of the vehicle.',
  'helper.monitoring.text_other': 'Monitor the current status of the vehicle.',
  'routesTrips.trip.work_type_one': 'Type of work 1',
  'routesTrips.trip.work_type_other': 'Type of work 1',
  'work_company.short_list.action_one': 'To create objects you will need to go to one of your companies. ',
  'work_company.short_list.action_other': 'To create objects you will need to go to one of your companies. ',
  'create.new_sensor': 'Create the first sensor',
  'create.copy.another_sensor': 'Copy from another object',
  'copy_sensors.select.all': 'All',
  'copy_sensors.select.name_object': 'Object name',
  'copy_sensors.select.protocol': 'Protocol',
  'copy_sensors.select.IMEI': 'IMEI',
  'copy_sensors.select.sim2': 'sim2',
  'copy_sensors.select.sensors': 'Sensors',
  'copy_sensors.select.groups': 'Groups',
  'linear.measurement': 'Linear measurement',
  'unitDisplays.format.acres': 'acres',
  'unitDisplays.format.feet': 'feet',
  'unitDisplays.format.kilometers': 'km',
  'unitDisplays.format.hectares': 'ha',
  'unitDisplays.format.meters': 'm',
  'unitDisplays.format.miles': 'miles',
  'unitDisplays.format.sqfeet': 'ft²',
  'unitDisplays.format.sqmeters': 'm²',
  'unitDisplays.format.sqmiles': 'mi²',
  'create.user.download.icon': 'Download icon',
  'create.user.password': '*Password for user',
  'create.user.password.repeat': '*Password again',
  'create.user.password.sameAs': 'Passwords must match',
  'create.user.password.rules.high': "The password must be between 15 and 25 characters long and contain at least one uppercase English letter, at least one lowercase English letter, and at least one special character (~ ! @ # $ % ^ & * ( ) - = + [ '{ ] '} | ; : ' \" , < . > / ?)",
  'create.user.password.rules.middle': "The password must be between 8 and 25 characters long and contain at least one uppercase English letter, at least one lowercase English letter, and at least one special character (~ ! @ # $ % ^ & * ( ) - = + [ '{ ] '} | ; : ' \" , < . > / ?)",
  'create.user.password.rules.easy': 'The password must be between 5 to 25 characters and must contain at least one English letter and at least one number',
  'create.user.not.required': 'Optional fields',
  'reports.table.placeholder': 'Search by tables',
  'access.settings.title': 'Access settings',
  'access.settings.groups': 'Access Groups',
  'access.settings.users': 'Users (Groups)',
  'access.settings.name.role': 'Role name:',
  'access.settings.table.name': 'BASIC',
  'access.settings.table.view': 'VIEW',
  'access.settings.table.create.and.change': 'CREATE AND CHANGE',
  'access.settings.warning.save': "Warning, you haven't saved your changes!",
  'access.settings.basic.title': 'General access',
  'access.settings.users.count': 'Users:',
  'access.settings.create.role': 'Create role',
  'access.settings.create.role.title': 'Role title',
  'access.settings.create.role.specify': 'Specify the role name',
  'access.settings.access.in.group': 'User accesses in group',
  'access.settings.create.user': 'Add user',
  'access.settings.create.btn': 'Added',
  'access.settings.create.btn.add': 'Add',
  'access.settings.access.on.group': 'User access by groups',
  'access.settings.access.group': 'GROUP',
  'access.settings.access.objects': 'OBJECTS:',
  'access.settings.access.add.in.group': 'Add to group',
  'access.modal.delete.role.title': 'Delete Role',
  'access.modal.delete.role': 'Role: ',
  'access.modal.delete.role.change': 'Replacement Role: ',
  'access.modal.delete.role.btn.cancel': 'Cancel',
  'access.modal.delete.role.btn.delete': 'Delete Role',
  'access.modal.delete.role.description.first': 'Users with the',
  'access.modal.delete.role.description.second': 'role will be assigned a new role (specify it above).',
  'access.modal.delete.role.description.third': 'Users with role',
  'access.settings.tooltip.change.role': 'Change role to',
  'access.settings.tooltip.edit.role': 'Edit role',
  'access.settings.tooltip.delete.from.group': 'Remove from group',
  'access.settings.tooltip.delete.user': 'Delete user',
  'reports.tables.fields.columns.error': 'The column title cannot be empty, please enter a column title!',
  'roles.notAllowed': "You don't have enough permissions for this action",
  'access.settings.access.users.essence': 'User access to objects',
  'access.settings.access.users.warning': 'Access is configured by adding an object to a group.'
}

export default translation
