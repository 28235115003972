import { geozonesApi } from '@/api'
import eventBus from '@/eventBus.js'

const state = {
  geozones: [],
  fitBoundsToGeozone: { '': false },
  color: { '#F56C6C': false },
  shape: { polygon: false },
  isDrawing: false,
  isEditing: { '': false },
  currentGeozone: {},
  width: { 1: false },
  type: { fillingstation: false },
  /// ИВЕНТЫ ДЛЯ ГЕОЗОН
  // allGeozonesEvent: false,
  addGeozoneEvent: { '': false },
  deleteGeozoneEvent: { '': false },
  updateGeozoneEvent: { '': false },
  hideEditedGeozone: { '': false },
  recoveryId: null,
  currentMeasure: null,
  measure: 'm',
  isHidingGeo: false,
  isClusterGeozones: false
}

const getters = {
  geozonesIdNameImei: state => {
    return state.geozones.map(unit => {
      return {
        id: unit.id,
        name: unit.name,
        color: unit.color
      }
    })
  },
  measure: state => state.measure,
  geozones_list: state => {
    console.log(state.geozones_list)
    return state.geozones
  }
}

const actions = {
  async SET_GEOZONES_DIMENSIONS({ commit }, formData) {
    await geozonesApi.setDimensions(formData, response => {
      commit('changeMeasure', formData.properties.geozone_dimensions)
    })
  },
  SET_CLUSTER_GEOZONES({ commit }, clusterGeozones) {
    commit('SET_CLUSTER_GEOZONES', clusterGeozones)
  },
  GET_GEOZONES({ commit }) {
    return new Promise(function (resolve) {
      geozonesApi.getGeozonesOnMap(
        response => {
          commit('SET_GEOZONES', response.data)

          resolve(response.data)
        },
        error => {
          eventBus.$showError(error.response.data.message)
        }
      )
    })
  },
  WEBSOCKET_CHANGE({ commit }, { id, operation }) {
    if (operation === 'DELETE') {
      commit('DELETE_GEOZONE', [{ id }])
    } else {
      geozonesApi.getGeozone(
        id,
        response => {
          if (operation === 'POST') {
            commit('ADD_GEOZONE', response.data)
          } else {
            commit('UPDATE_GEOZONE', response.data)
          }
        },
        error => {
          eventBus.$showError(error.response.data.message)
        }
      )
    }
  },
  CREATE_GEOZONE({ commit }, formData) {
    if (formData.type.key === 'polygon') delete formData.width
    if (formData.color.key === '#4ABC96') {
      formData.color.key = '#1BB934'
    } else if (formData.color.key === '#2F80ED') {
      formData.color.key = '#1585D8'
    }
    return new Promise((resolve, reject) => {
      geozonesApi.createGeozone(
        { formData },
        function (response) {
          commit('ADD_GEOZONE', response.data)
          resolve()
        },
        function (error) {
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  UPDATE_GEOZONE({ commit }, formData) {
    if (formData.color.key === '#4ABC96') {
      formData.color.key = '#1BB934'
    } else if (formData.color.key === '#2F80ED') {
      formData.color.key = '#1585D8'
    }
    return new Promise((resolve, reject) => {
      geozonesApi.updateGeozone(
        formData.id,
        { formData },
        response => {
          // при сокете приходит обнвление PUT, поэтому пока заккоменчу
          // commit('UPDATE_GEOZONE', response.data)
          resolve()
        },
        error => {
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  DELETE_GEOZONE({ commit, rootState }, geozones) {
    rootState.reports.templates.forEach(template => {
      template.template_tables.forEach(table => (table.geozones = table.geozones.filter(geo => !geozones.map(geozone => geozone.id).includes(geo.id))))
    })
    const promiseList = []

    promiseList.push(
      new Promise(function (resolve, reject) {
        geozonesApi.deleteGeozone(geozones, resolve, reject)
      })
    )

    Promise.all(promiseList)
      .then(res => {
        commit('DELETE_GEOZONE', geozones)
        commit('SET_RECOVERYID', res[0].data)
      })
      .catch(function (error) {
        console.log(error)
        eventBus.$showError(error.response.data.message)
      })
  },
  RECOVER_TRAILERS({ commit }, data) {
    return new Promise((resolve, reject) => {
      geozonesApi.recover(
        data.id,
        response => {
          commit('RECOVER', data)
          resolve()
        },
        error => {
          console.log(error)
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  }
}

const mutations = {
  TOGGLE_GEOZONES(state, payload) {
    state.isHidingGeo = payload
  },
  changeMeasure(state, payload) {
    state.currentMeasure = payload
  },
  RECOVER(state, units) {
    // восстановление удаленных геозон
    units.itemsDeleted.forEach((unit, idx) => {
      state.geozones.splice(unit.index, 0, unit)
    })
    state.addGeozoneEvent = units.itemsDeleted.map(unit => unit.id)
  },
  SET_RECOVERYID(state, id) {
    state.recoveryId = id
  },
  HIDE_EDITEDGEO(state, geozoneId) {
    state.hideEditedGeozone = { [geozoneId]: true }
  },
  SET_GEOZONES(state, geozones) {
    const indexedItems = geozones.map((item, index) => ({
      index,
      ...item
    }))
    state.geozones = indexedItems
  },
  ADD_GEOZONE(state, geozone) {
    if (!state.geozones.some(x => x.id === geozone.id)) {
      state.geozones = [...state.geozones, geozone]
      state.addGeozoneEvent = {
        [geozone.id]: !Object.values(state.addGeozoneEvent)[0]
      }
    }
  },
  RESET_GEOZONES(state, geozones) {
    state.geozones = geozones
  },
  DELETE_GEOZONE(state, geozones) {
    console.log('🚀 ~ DELETE_GEOZONE ~ geozones:', geozones)
    state.geozones = state.geozones.filter(item => !geozones.map(k => k.id).includes(item.id))
    state.deleteGeozoneEvent = geozones.map(geo => geo.id)
  },
  UPDATE_GEOZONE(state, geozone) {
    const indexOfGeozone = state.geozones.findIndex(elem => elem.id === geozone.id)
    state.geozones = [...state.geozones.slice(0, indexOfGeozone), geozone, ...state.geozones.slice(indexOfGeozone + 1)]
    state.updateGeozoneEvent = {
      [geozone.id]: !Object.values(state.updateGeozoneEvent)[0]
    }
  },
  FITBOUNDS_TO_GEOZONE(state, fitBoundsToGeozone) {
    state.fitBoundsToGeozone = {
      [fitBoundsToGeozone]: !Object.values(state.fitBoundsToGeozone)[0]
    }
  },
  SET_COLOR(state, color) {
    state.color = {
      [color]: !Object.values(state.color)[0]
    }
  },
  SET_WIDTH(state, width) {
    state.width = {
      [width]: !Object.values(state.width)[0]
    }
  },
  SHAPE_CHANGE(state, shape) {
    state.shape = {
      [shape]: !Object.values(state.shape)[0]
    }
  },
  TYPE_CHANGE(state, type) {
    state.type = {
      [type]: !Object.values(state.type)[0]
    }
  },
  SET_GEOZONE_COORDS(state, currentGeozone) {
    state.currentGeozone = currentGeozone
  },
  START_DRAWING(state) {
    state.isDrawing = true
  },
  END_DRAWING(state) {
    state.isDrawing = false
  },
  START_EDITING(state, geozoneId) {
    state.isEditing = { [geozoneId]: true }
  },
  END_EDITING(state, geozoneId) {
    state.isEditing = { [geozoneId]: false }
  },
  SET_MEASURE(state, value) {
    if (value) state.measure = value
  },
  SET_CLUSTER_GEOZONES(state, clusterGeozones) {
    state.isClusterGeozones = clusterGeozones
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
