import { loginApi } from '@/api'
import eventBus from '@/eventBus.js'
import axios from 'axios'
import router from '../../router/router'

const defaultState = {
  requestedRoute: null,
  branding: {},
  selectedModule: {},
  centerMapClear: true,
  isOnline: true,
  loading: false,
  disabledRole: false,
  notRole: false,
  successInvite: false,
  isAuthenticated: false,
  isUnauthorized: false,
  id: localStorage.getItem('me') || '',
  changingCompany: false,
  errorRegistration: '',
  regions: '',
  isLoggedDemo: '',
  me: {
    id: '',
    name: '',
    role: {
      value: '',
      key: ''
    },
    lang: {
      key: ''
    },
    photos: [],
    active_company: {
      id: '',
      timezone: {
        key: ''
      },
      billing: {
        company_status: ''
      }
    }
  },
  formattedCurrentTime: '',
  recovery: false,
  isNeedChangePassword: false
}

const getters = {
  getIsUnauthorized: state => state.isUnauthorized,

  getRecovery: (state) => {
    return state.recovery
  },

  getBranding: (state) => {
    return state.branding
  },
  centerMapClear: (state) => {
    return state.centerMapClear
  },
  isOnline: (state) => {
    return state.isOnline
  },
  getAuth: (state) => {
    return state.id
  },
  role: (state) => {
    return state.me.role.key
  },
  disabledRole: (state) => {
    return state.disabledRole
  },
  notRole: (state) => {
    return state.notRole
  },
  changingCompany: (state) => {
    return state.changingCompany
  },
  activeCompanyId: (state) => {
    return state.me.active_company.id
  },
  activeCompany: (state) => {
    return state.me.active_company
  },
  timezone: (state) => {
    return state.me.active_company.timezone.key
  },
  companies: (state) => {
    return state.me.companies
  },
  me: (state) => state.me,
  roles: (state) => {
    return state.me.role
  },
  timeZoneDiff: (state) => {
    return state.me.active_company.timezone.key.match(/\d+/)
      ? state.me.active_company.timezone.key.match(/\d+/)[0]
      : '0'
  },
  name: (state) => {
    return state.me.name
  },
  userId: (state) => {
    return state.me.id
  },
  userEmail: (state) => {
    return state.me.email
  },

  user: (state) => {
    return state.me
  },

  formattedCurrentTime: (state) => {
    return state.formattedCurrentTime
  },
  currency: (state) => {
    return state.me.active_company.billing.currency.display_name || ''
  },
  balance: (state) => {
    return state.me.active_company.billing.amount.toString()
  },
  companyBilling: (state) => {
    return state.me.active_company.billing
  }
}

const actions = {
  setRequestedRoute({ commit }, route) {
    commit('SET_REQUESTED_ROUTE', route)
  },
  getBranding({ commit }) {
    return new Promise((resolve, reject) => {
      loginApi.getBranding(
        (response) => {
          const tempBranding = {
            domain: 'test.skif.pro',
            title: 'Horns&Hoofs',
            logo: '/files/branding/logo_5437872.png',
            background: '/files/branding/background_5437872.jpg',
            mail_header: '/files/branding/mail_header_5437872.jpg',
            site: 'https://skif.pro',
            contacts:
              'kаша из топора» — народная сказка, которую хоть раз слышал каждый. В ней рассказывается о необычном рецепте блюда и смекалке русского человека. Солдат идёт домой на побывку, остановился на ночь у скупой старушки, которая не захотела накормить его ужином. Тогда служивый не растерялся: предложил сварить ей кашу «хоть из топора». Старуха удивилась, но согласилась. Через некоторое время и она, и солдат были сытыми, а бабка и не почувствовала подвоха. ',
            is_powered: true,
            _powered: true,
            _delete: false
          }

          commit('SET_BRANDING', response.data)
          // commit('SET_BRANDING', response.data)
          resolve()
        },
        (err) => {
          console.log('error', err)
          reject(err)
        }
      )
    })
  },

  openModuleAdminPanel({ commit }, formData) {
    commit('SET_SELECTED_MODULE', formData)
  },
  setCompanyId({ commit }, formData) {
    commit('SET_SELECTED_MODULE', formData)
  },
  GET_TERMS_BY_QR({ commit }, code) {
    loginApi.getTermsQR(
      code,
      (response) => {
        window.open(URL.createObjectURL(response.data))
      },
      (err) => {
        console.log('error getting code', err)
      }
    )
  },
  GET_TERMS({ commit }, companyId) {
    loginApi.getTerms(
      companyId,
      (response) => {
        window.open(URL.createObjectURL(response.data))
      },
      (err) => {
        console.log('error getting code', err)
      }
    )
  },
  ACTIVATE_QRCODE({ commit }, isActivated) {
    commit('ACTIVATE_QRCODE', isActivated)
  },
  ADD_REGIONS({ commit }, regions) {
    commit('ADD_REGIONS', regions)
  },
  ADD_REGION({ commit }, regions) {
    commit('ADD_REGION', regions)
  },
  NEWBILLING({ commit }, tariff) {
    commit('set_newBilling', tariff)
  },
  UPDATE_COMPANIES({ commit }, companies) {
    commit('UPDATE_COMPANY', companies)
  },
  getMe({ commit }) {
    return new Promise((resolve, reject) => {
      loginApi.getMe(
        (response) => {
          const { id } = response.data;
          localStorage.setItem('me', id);
          axios.defaults.headers.common.Authorization = id;
  
          if (response.data.need_change_password) {
            // router.push('/changePassword')
            commit('SET_CHANGE_PASSWORD', true);
          }
  
          commit('SAVE_ME', response.data);
          commit('SET_AUTH', id);
          commit('SAVE_ROLE', response.data);
          commit('SET_UNAUTHORIZED', true);
  
          resolve(response.data);
        },
        (error) => {
          commit('SET_UNAUTHORIZED', false);
          if (error.response.status === 401) {
            router.push('/login')
          }
          eventBus.$showError(error.response.data.message);
          localStorage.removeItem('me');
          reject(error.response);
        }
      );
    });
  },

  login({ dispatch, commit, state }, formData) {
    loginApi.login(
      {
        userProviderId: formData.email,
        provider_key: 'EMAIL',
        password: formData.pass
      },
      (_) => {
        dispatch('getMe').then((_) => {
          const { requestedRoute } = state

          if (requestedRoute) {
            router.push(requestedRoute)
            dispatch('setRequestedRoute', null)
          } else {
            router.push('/')
          }
          localStorage.setItem('typeLogged', 'fullLogin')
        })
      },
      function (error) {
        error.response.data.message
          ? eventBus.$showError(error.response.data.message)
          : eventBus.$showError(
              ' К сожалению, сервер временно недоступен. Попробуйте повторить действие чуть позже.'
            )
        console.log('eorro', error)
      }
    )
  },

  loginDemoVersion({ commit, dispatch }) {
    const demoUser = {
      password: '12345N',
      privider_key: 'EMAIL',
      userProviderId: 'test1@ya.ru'
    }
    loginApi.demoVersion(
      {
        userProviderId: demoUser.userProviderId,
        provider_key: demoUser.provider_key,
        password: demoUser.password
      },
      (response) => {
        dispatch('getMe').then((_) => {
          localStorage.setItem('typeLogged', 'demoLogin')
          router.push('/')
        })
      },
      (error) => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },

  logout({ state, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      loginApi.logout(
        function () {
          state.me.companies.forEach((company) => {
            const filter = localStorage.getItem(`filtredUnits_${company.id}`)
            const reportForm = localStorage.getItem(
              `reportsForm${state.me.id}${company.id}`
            )

            if (filter) localStorage.removeItem(`filtredUnits_${company.id}`)
            if (reportForm)
              localStorage.removeItem(`reportsForm${state.me.id}${company.id}`)
          })
          localStorage.removeItem('me')
          localStorage.removeItem('typeLogged')

          localStorage.setItem('monitoringMarkers', JSON.stringify([]))
          dispatch('monitoringNew/CLEAR_EYE_ARROW', null, { root: true })

          commit('SET_AUTH', '')
          commit('SET_UNAUTHORIZED', false);
          resolve()
        },
        function (error) {
          console.log(error)
          eventBus.$showError(error.response.data.message)
          reject(error)
          setTimeout(function () {
            window.location.reload()
          }, 3000)
        }
      )
    })
  },

  // Регистрация
  registrate(_, formData) {
    return new Promise(function (resolve, reject) {
      loginApi.registrate(
        formData,
        function (response) {
          router.push('confirm')
          resolve(response.data)
        },
        function (error) {
          // eventBus.$showError(error.response.data.message)
          reject(error.response.data)
          // router.push('register')
        }
      )
    })
  },

  // Восстановление
  recovery({ commit }, formData) {
    loginApi.recovery(
      formData,
      function () {
        // router.push('/recovery2')
        commit('RESET_RECOVERY')
      },
      function (error) {
        eventBus.$showError(error.response.data.message)
      }
    )
  },

  // Смена пароля
  changePassword(_, formData) {
    return new Promise(function (resolve, reject) {
      loginApi.changeMyPassword(
        formData,
        function (response) {
          router.push('/')
          resolve(response.data)
        },
        function (error) {
          eventBus.$showError(error.response.data.message)
          reject(error.response.data)
        }
      )
    })
  },

  // Загрузка аватара
  async uploadAvatar({ commit }, formData) {
    try {
      const data = await loginApi.uploadAvatar(formData)

      commit('SET_PHOTOS', data)

      return data
    } catch (err) {
      throw new Error(err.response.data.message)
    }
  }
}

const mutations = {
  SET_UNAUTHORIZED(state, status) {
    state.isUnauthorized = status;
  },
  SET_REQUESTED_ROUTE(state, payload) {
    state.requestedRoute = payload
  },
  RESET_RECOVERY(state) {
    state.recovery = !state.recovery
  },
  SET_BRANDING(state, payload) {
    state.branding = payload
  },
  SET_SELECTED_MODULE(state, payload) {
    state.selectedModule = payload
  },
  setBillingInfo(state, payload) {
    state.me.active_company.billing = payload
  },
  IS_DEMO_LOGGED(state, isLoggedDemo) {
    state.isLoggedDemo = isLoggedDemo
  },
  ACTIVATE_QRCODE(state, isActivated) {
    state.isQRcodeActive = isActivated
  },
  UPDATE_COMPANY(state, companies) {
    state.me = {
      ...state.me,
      companies
    }
  },
  ADD_REGIONS(state, regions) {
    state.regions = regions
  },
  CENTER_MAP_CLEAR(state) {
    state.centerMapClear = !state.centerMapClear
  },
  IS_ONLINE(state, online) {
    state.isOnline = online
  },
  ADD_REGION(state, regions) {
    state.regions = [...state.regions, ...regions]
  },

  set_newBilling(state, billing) {
    state.me = {
      ...state.me,
      active_company: {
        ...state.me.active_company,
        billing
      }
    }
  },
  SET_AUTH(state, id) {
    state.isAuthenticated = true
    state.id = id
  },
  SET_CHANGE_PASSWORD(state, payload) {
    state.isNeedChangePassword = payload
  },

  SET_ACTIVE_COMPANY(state, name) {
    state.me = {
      ...state.me,
      active_company: {
        ...state.me.active_company,
        name
      }
    }
  },

  SET_TZ_ACTIVE_COMPANY(state, value) {
    state.me = {
      ...state.me,
      active_company: {
        ...state.me.active_company,
        timezone: {
          ...state.me.active_company.timezone,
          value
        }
      }
    }
  },
  SUCCESS_INVITE(state) {
    state.successInvite = true
  },
  REMOVE_SUCCESS_INVITE(state) {
    state.successInvite = false
  },

  SAVE_ME(state, data) {
    state.me = data
    if (!data.active_company.is_admin_panel) {
      localStorage.setItem('active_company', data.active_company.id)
    }
  },

  SAVE_ROLE(state, data) {
    const isRole = data.role.key

    state.disabledRole =
      isRole !== 'ADMIN' &&
      isRole !== 'SUPERVISOR' &&
      isRole !== 'TARIFF_EDITOR' &&
      isRole !== 'CONTROLLER' &&
      isRole !== 'EDITOR'
    state.notRole =
      isRole === 'ADMIN' ||
      isRole === 'SUPERVISOR' ||
      isRole === 'TARIFF_EDITOR' ||
      isRole === 'CONTROLLER'
  },

  setLoading(state) {
    state.loading = !state.loading
  },
  COMPANY_CHANGED(state) {
    state.changingCompany = !state.changingCompany
  },

  setFormattedCurrentTime(state, payload) {
    state.formattedCurrentTime = payload
  },
  SET_PHOTOS(state, payload) {
    state.me.photos = payload
  },
  SET_AUTO_PAY(state, payload) {
    state.me.active_company.billing.auto_pay.is_active = payload
  }
}

export default {
  namespaced: true,
  state: defaultState,
  actions,
  mutations,
  getters
}
